import { omit, deepCopy } from '@budgetdumpster/scms-ui'

export const currentMarketStore = {
  namespaced: true,
  state: {
    market: {
      name: ''
    },
    temporaryConstructionAcceptedMaterials: [],
    temporaryResidentialAcceptedMaterials: [],
    permanentAcceptedMaterials: [],
    temporaryDumpsterSizes: [],
    permanentDumpsterSizes: [],
    specialUseDumpsterSizes: []
  },
  getters: {
    apiFormattedObject (state) {
      const tempMarket = { ...state.market }
      tempMarket['temporaryConstructionAcceptedMaterials'] = state.temporaryConstructionAcceptedMaterials
      tempMarket['temporaryResidentialAcceptedMaterials'] = state.temporaryResidentialAcceptedMaterials
      tempMarket['permanentAcceptedMaterials'] = state.permanentAcceptedMaterials
      tempMarket['temporaryDumpsterSizes'] = processDumpsterSizes(state.temporaryDumpsterSizes)
      tempMarket['permanentDumpsterSizes'] = processDumpsterSizes(state.permanentDumpsterSizes)
      tempMarket['specialUseDumpsterSizes'] = processDumpsterSizes(state.specialUseDumpsterSizes)
      return tempMarket
    }
  },
  actions: {},
  mutations: {
    resetMarket (state) {
      state.market = {}
      state.temporaryConstructionAcceptedMaterials = ['CONSTRUCTION_DEBRIS', 'WOOD_DRYWALL', 'ROOFING_MATERIALS']
      state.temporaryResidentialAcceptedMaterials = ['HOUSEHOLD_JUNK', 'CARPET', 'FLOORING', 'APPLIANCES', 'ELECTRONICS', 'FURNITURE', 'MATTRESS_BOX_SPRINGS', 'YARD_WASTE']
      state.permanentAcceptedMaterials = ['PAPER', 'GENERAL_TRASH', 'CARDBOARD', 'PLASTIC', 'PACKAGING_MATERIALS', 'OFFICE_SUPPLIES', 'FOOD_WASTE', 'BROKEN_DISHWARE', 'ELECTRONICS']
      state.temporaryDumpsterSizes = []
      state.permanentDumpsterSizes = []
      state.specialUseDumpsterSizes = []
    },
    setMarket (state, payload) {
      state.market = omit([
        'temporaryConstructionAcceptedMaterials',
        'temporaryResidentialAcceptedMaterials',
        'permanentAcceptedMaterials',
        'temporaryDumpsterSizes',
        'permanentDumpsterSizes',
        'specialUseDumpsterSizes'
      ], payload)
      state.temporaryConstructionAcceptedMaterials = payload.temporaryConstructionAcceptedMaterials || []
      state.temporaryResidentialAcceptedMaterials = payload.temporaryResidentialAcceptedMaterials || []
      state.permanentAcceptedMaterials = payload.permanentAcceptedMaterials || []
      state.temporaryDumpsterSizes = payload.temporaryDumpsterSizes || []
      state.permanentDumpsterSizes = payload.permanentDumpsterSizes || []
      state.specialUseDumpsterSizes = payload.specialUseDumpsterSizes || []
    },
    setAttribute (state, payload) {
      if (!payload.name) {
        console.error(`CurrentMarketStore: No name provided to update market attribute`)
        return
      }

      const marketClone = { ...state.market }
      marketClone[payload.name] = payload.value
      state.market = marketClone
    },
    addDumpsterSize (state, payload) {
      switch (payload.categoryName) {
        case 'TEMPORARY':
          state.temporaryDumpsterSizes.push(payload.value)
          break
        case 'PERMANENT':
          state.permanentDumpsterSizes.push(payload.value)
          break
        case 'SPECIAL_USE':
          state.specialUseDumpsterSizes.push(payload.value)
          break
        default:
          console.error(`Invalid dumpster category: ${payload.categoryName}`)
      }
    },
    setDumpsterSizes (state, payload) {
      switch (payload.categoryName) {
        case 'TEMPORARY':
          state.temporaryDumpsterSizes = payload.value
          break
        case 'PERMANENT':
          state.permanentDumpsterSizes = payload.value
          break
        case 'SPECIAL_USE':
          state.specialUseDumpsterSizes = payload.value
          break
        default:
          console.error(`Invalid dumpster category: ${payload.categoryName}`)
      }
    },
    setAcceptedMaterials (state, payload) {
      state[payload.name] = payload.value
    }
  }
}

function processDumpsterSizes (dumpsterSize) {
  const tempDumpsterSize = deepCopy(dumpsterSize).map(dumpster => {
    if (!dumpster.hasOwnProperty('dumpsterSizeId')) {
      dumpster.dumpsterSizeId = dumpster.dumpsterSize.id
    }
    return omit(['dumpsterSize', '__typename'], dumpster)
  })
  return tempDumpsterSize
}

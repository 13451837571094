<template>
  <div class="mr-3">
    <router-link to="/dumpsters">&larr; Back To All Dumpsters</router-link>
    <button class="bg-primary m-5 float-right" @click="saveDumpsterSize">Save Changes</button>
    <h1 class="text-primary my-5">
      {{ $store.state.currentDumpsterSizeStore.dumpsterSize.title ? $store.state.currentDumpsterSizeStore.dumpsterSize.title : 'New Dumpster' }}
    </h1>
    <div>
      <h5 class="m-0 text-secondary border-top border-bottom p-2">INFORMATION</h5>
      <div class="bg-light p-4">
        <div class="row mx-4 mb-4">
          <label class="col-4">
            Title
            <input class="form-control" type="text" v-model="title"/>
            <em class="float-right text-danger">*Required</em>
          </label>
          <scms-select class="col-4" v-model="uom" :options="uomList" label="Weight Unit of Measure" :should-return-key="true"/>
        </div>
        <div class="row mx-4 mb-4">
          <scms-select class="col-4" v-model="category" :options="dumpsterSizeCategories" label="Dumpster type" :should-return-key="true"/>
          <label class="col-2">
            Minimum Weight (General)
            <input
              class="form-control"
              v-model.number="minimumWeightGeneral"
              :max="weightLimitGeneral"
              type="number"
              step="0.01"/>
          </label>
          <label class="col-2">
            Weight Limit (General)
            <input
              class="form-control"
              v-model.number="weightLimitGeneral"
              type="number"
              step="0.01"/>
          </label>
        </div>
        <div class="row mx-4 mb-4">
          <label class="col-4">
            Average Cost
            <input class="form-control" type="text" v-model="averageCost"/>
          </label>
          <label class="col-2">
            Minimum Weight (Heavy)
            <input
              class="form-control"
              v-model.number="minimumWeightHeavy"
              :max="weightLimitHeavy"
              type="number"
              step="0.01"/>
          </label>
          <label class="col-2">
            Weight Limit (Heavy)
            <input
              class="form-control"
              v-model.number="weightLimitHeavy"
              type="number"
              step="0.01"/>
          </label>
        </div>
        <div class="row mx-4 mb-4">
          <label class="col-4">
            Page Url
            <input class="form-control" type="text" v-model="pageUrl"/>
          </label>
          <label class="col-4">
            Dimensions
            <input class="form-control" type="text" v-model="dimensions"/>
          </label>
        </div>
      </div>
    </div>
    <scms-copy-block label='"Best For" or Common Uses' class='pt-3 mt-3' v-model='bestFor'/>
    <div>
      <h5 class="m-0 text-secondary border-top border-bottom p-2">IMAGES</h5>
      <div class="bg-light p-4">
        <div class="row mx-4 mb-4">
          <label class="col-4">
            Image Title
            <input class="form-control" type="text" v-model="imageTitle"/>
          </label>
          <label class="col-4">
            Image Alt
            <input class="form-control" type="text" v-model="imageAlt"/>
          </label>
        </div>
        <div class="row mx-2 mb-4">
          <div class="col-6">
            <label class="col-9">
              Small JPG Image
              <input class="form-control" type="text" v-model.lazy="smallImageSrc">
              <em class="float-right text-danger">*Required</em>
            </label>
            <div class="col-9">
              <img v-if="smallImageSrc" class="img-thumbnail my-1" :src="smallImageSrc">
            </div>
          </div>
          <div class="col-6">
            <label class="col-9">
              Small WebP Image
              <input class="form-control" type="text" v-model.lazy="smallWebpImageSrc" @click="setSmallWebpImageSrc()">
            </label>
            <div class="col-9">
              <img v-if="smallWebpImageSrc" class="img-thumbnail my-1" :src="smallWebpImageSrc">
            </div>
          </div>
        </div>
        <div class="row mx-2 mb-4">
          <div class="col-6">
            <label class="col-9">
              Medium JPG Image
              <input class="form-control" type="text" v-model.lazy="mediumImageSrc">
              <em class="float-right text-danger">*Required</em>
            </label>
            <div class="col-9">
              <img v-if="mediumImageSrc" class="img-thumbnail my-1" :src="mediumImageSrc">
            </div>
          </div>
          <div class="col-6">
            <label class="col-9">
              Medium WebP Image
              <input class="form-control" type="text" v-model.lazy="mediumWebpImageSrc" @click="setMediumWebpImageSrc()">
            </label>
            <div class="col-9">
              <img v-if="mediumWebpImageSrc" class="img-thumbnail my-1" :src="mediumWebpImageSrc">
            </div>
          </div>
        </div>
        <div class="row mx-2 mb-4">
          <div class="col-6">
            <label class="col-9">
              Large JPG Image
              <input class="form-control" type="text" v-model.lazy="largeImageSrc">
            </label>
            <div class="col-9">
              <img v-if="largeImageSrc" class="img-thumbnail my-1" :src="largeImageSrc">
            </div>
          </div>
          <div class="col-6">
            <label class="col-9">
              Large WebP Image
              <input class="form-control" type="text" v-model.lazy="largeWebpImageSrc" @click="setLargeWebpImageSrc()">
            </label>
            <div class="col-9">
              <img v-if="largeWebpImageSrc" class="img-thumbnail my-1" :src="largeWebpImageSrc">
            </div>
          </div>
        </div>
      </div>
      <button class="bg-primary m-2" @click="saveDumpsterSize">Save Changes</button>
    </div>
  </div>
</template>

<script>
import { ScmsSelect, getWebPImageSrc } from '@budgetdumpster/scms-ui'
import { mapMutations, mapActions } from 'vuex'
import { dumpsterSizeService } from '../services/DumpsterSizeService'
import ScmsCopyBlock from '@budgetdumpster/scms-ui/src/components/CopyBlock'

export default {
  name: 'scms-add-edit-dumpster-size',
  components: { ScmsCopyBlock, ScmsSelect },
  beforeUpdate () {
    if (this.smallImageSrc && !this.smallWebpImageSrc) {
      this.smallWebpImageSrc = getWebPImageSrc(this.smallImageSrc)
    }
    if (this.mediumImageSrc && !this.mediumWebpImageSrc) {
      this.mediumWebpImageSrc = getWebPImageSrc(this.mediumImageSrc)
    }
    if (this.largeImageSrc && !this.largeWebpImageSrc) {
      this.largeWebpImageSrc = getWebPImageSrc(this.largeImageSrc)
    }
  },
  data () {
    return {
      dumpsterSizeCategories: { 'TEMPORARY': 'Temporary', 'PERMANENT': 'Permanent', 'SPECIAL_USE': 'Special-Use' },
      uomList: { 'pounds': 'Pounds', 'tons': 'Tons', 'kilograms': 'Kilograms' }
    }
  },
  beforeCreate () {
    if (!this.$route.params.hasOwnProperty('id')) {
      this.$store.commit('currentDumpsterSizeStore/setDumpsterSize', { dumpsterSize: {} })
      return
    }
    dumpsterSizeService.getDumpsterSizeByIdAndCategory(this.$route.params.id, this.$route.params.category).then((response) => {
      this.$store.commit('currentDumpsterSizeStore/setDumpsterSize', { dumpsterSize: response })
    }).catch(() => {
      this.$router.push({ path: `/dumpsters` })
    })
  },
  methods: {
    ...mapMutations('modalStore', ['openModal', 'closeModal']),
    ...mapActions('notificationStore', {
      notify: 'add'
    }),
    setSmallWebpImageSrc () {
      this.smallWebpImageSrc = getWebPImageSrc(this.smallImageSrc)
    },
    setMediumWebpImageSrc () {
      this.mediumWebpImageSrc = getWebPImageSrc(this.mediumImageSrc)
    },
    setLargeWebpImageSrc () {
      this.largeWebpImageSrc = getWebPImageSrc(this.largeImageSrc)
    },
    isMinLessThanLimit (typesToCheck) {
      // Doing an equal check here as well to account for both being empty (which is valid and totally not against the rules)
      return typesToCheck.every((v) => {
        const min = Number(this[`minimumWeight${v}`]) || 0
        const max = Number(this[`weightLimit${v}`]) || 0
        return min <= max
      })
    },
    saveDumpsterSize () {
      if (!this.title || !this.mediumImageSrc || !this.smallImageSrc) {
        this.notify({ type: 'danger', message: 'Please fill all required fields' })
        return
      }

      if (!this.isMinLessThanLimit(['Heavy', 'General'])) {
        this.notify({ type: 'danger', message: 'Minimum Weight field is greater than limit' })
        return
      }

      if (!this.$route.params.hasOwnProperty('id')) {
        dumpsterSizeService.saveDumpsterSize(this.$store.state.currentDumpsterSizeStore.dumpsterSize)
          .then((response) => {
            this.$router.push({ path: `/dumpsters/${response.category.toLowerCase()}/edit/${response.id}` })
            this.notify({ type: 'success', message: 'Dumpster Size successfully saved' })
          })
        return
      }
      dumpsterSizeService.updateDumpsterSize(this.$route.params.id, this.$store.state.currentDumpsterSizeStore.dumpsterSize)
        .then(() => {
          this.notify({ type: 'success', message: 'Dumpster Size successfully updated' })
        })
    }
  },
  computed: {
    title: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.title
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'title',
          value: value
        })
      }
    },
    minimumWeightGeneral: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.minimumWeightGeneral
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'minimumWeightGeneral',
          value: value || null
        })
      }
    },
    weightLimitGeneral: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.weightLimitGeneral
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'weightLimitGeneral',
          value: value || null
        })
      }
    },
    minimumWeightHeavy: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.minimumWeightHeavy
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'minimumWeightHeavy',
          value: value || null
        })
      }
    },
    weightLimitHeavy: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.weightLimitHeavy
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'weightLimitHeavy',
          value: value || null
        })
      }
    },
    uom: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.uom
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'uom',
          value: value
        })
      }
    },
    category: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.category
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'category',
          value: value
        })
      }
    },
    averageCost: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.averageCost
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'averageCost',
          value: value
        })
      }
    },
    dimensions: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.dimensions
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'dimensions',
          value: value
        })
      }
    },
    imageTitle: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.imageTitle
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'imageTitle',
          value: value
        })
      }
    },
    imageAlt: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.imageAlt
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'imageAlt',
          value: value
        })
      }
    },
    smallImageSrc: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.smallImageSrc
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'smallImageSrc',
          value: value
        })
      }
    },
    mediumImageSrc: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.mediumImageSrc
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'mediumImageSrc',
          value: value
        })
      }
    },
    largeImageSrc: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.largeImageSrc
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'largeImageSrc',
          value: value
        })
      }
    },
    smallWebpImageSrc: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.smallWebpImageSrc
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'smallWebpImageSrc',
          value: value
        })
      }
    },
    mediumWebpImageSrc: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.mediumWebpImageSrc
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'mediumWebpImageSrc',
          value: value
        })
      }
    },
    largeWebpImageSrc: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.largeWebpImageSrc
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'largeWebpImageSrc',
          value: value
        })
      }
    },
    pageUrl: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.pageUrl
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'pageUrl',
          value: value
        })
      }
    },
    bestFor: {
      get () {
        return this.$store.state.currentDumpsterSizeStore.dumpsterSize.bestFor
      },
      set (value) {
        this.$store.commit('currentDumpsterSizeStore/setAttribute', {
          name: 'bestFor',
          value: value
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .plus-circle {
    font-size: 22px;
  }

  .list-heading {
    font-size: 13px;
  }
</style>

<template>
  <scms-module-accordion-card :title="moduleTitle">
    <template slot="component">
      <scms-edit-breadcrumb :value="breadcrumbArr" v-model="breadcrumbArr" class="mb-3"/>
    </template>
    <template slot="component-styles">
      <div class="row">
        <scms-select class="col-4" v-if="moduleMeta.backgroundColors" v-model="backgroundColor"
          :options="moduleMeta.backgroundColors" label="Background Color" :should-return-key="true"/>
      </div>
      <fieldset class="form-group mr-4">
        <legend class="col-form-label pt-0">Indentation</legend>
        <label class="form-check-inline">
          <input class="form-check-input" type="radio" :name="'indentation' + moduleIndex" :value="true" v-model="indentation">
          Indented
        </label>
        <label class="form-check-inline">
          <input class="form-check-input" type="radio" :name="'indentation' + moduleIndex" :value="false" v-model="indentation">
          Not Indented
        </label>
      </fieldset>
    </template>
    <template slot="component-footer">
      <label class="col-6">
        Module Unique ID Name
        <input type="text" class="form-control" v-model="moduleId">
      </label>
    </template>
  </scms-module-accordion-card>
</template>

<script>
import { mapModuleAttributes, moduleMixin, ScmsModuleAccordionCard, ScmsSelect, ScmsEditBreadcrumb } from '@budgetdumpster/scms-ui'

export default {
  name: 'scms-breadcrumbs',
  mixins: [moduleMixin],
  components: {
    ScmsModuleAccordionCard,
    ScmsSelect,
    ScmsEditBreadcrumb
  },
  created () {
    if (!this.breadcrumbs.length) {
      if (!this.page.pageUrl) {
        return [{}]
      }

      const urlFragments = this.page.pageUrl.slice(1).split('/')

      const breadcrumbs = urlFragments.reduce((acc, currentUrl, index) => {
        const previous = acc[index - 1]
        const previousUrl = previous ? previous.url : ''

        return [
          ...acc,
          {
            title: currentUrl.replace(/-/g, ' '),
            url: `${previousUrl}/${currentUrl}`
          }
        ]
      }, [])
      this.breadcrumbArr = breadcrumbs
    }
  },
  computed: {
    breadcrumbArr: {
      get () {
        return this.page.modules[this.moduleIndex].data.breadcrumbs
      },
      set (value) {
        this.setModuleAttribute({ index: this.moduleIndex, name: 'breadcrumbs', value })
      }
    },
    ...mapModuleAttributes(['breadcrumbs', 'backgroundColor', 'indentation'])
  }
}
</script>

<template>
  <scms-module-accordion-card :title="(heading.tag) ? `${moduleTitle} [${heading.tag}]` : moduleTitle">
    <template slot="component">
      <scms-heading-with-level v-model="heading"/>
      <scms-copy-block v-model="copyText" label="Top Copy Block"/>
      <scms-item-list item-name="ScmsAcceptedMaterialsListItem" v-model="cards" list-name="Card Section"
        :new-item-data="cardItemDefault()"/>
    </template>
    <template slot="component-styles">
      <div class="row">
        <scms-select class="col-4" v-if="moduleTopPaddingOptions" v-model="paddingTop"
          :options="moduleTopPaddingOptions" label="Top Padding" :should-return-key="true"/>
        <scms-select class="col-4" v-if="moduleBottomPaddingOptions" v-model="paddingBottom"
          :options="moduleBottomPaddingOptions" label="Bottom Padding" :should-return-key="true"/>
      </div>
    </template>
    <template slot="component-footer">
      <label class="col-6">
        Module Unique ID Name
        <input type="text" class="form-control" v-model="moduleId">
      </label>
    </template>
  </scms-module-accordion-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { moduleMixin, mapModuleAttributes, deepCopy, ScmsModuleAccordionCard, ScmsHeadingWithLevel, ScmsCopyBlock, ScmsItemList, ScmsSelect } from '@budgetdumpster/scms-ui'

export default {
  name: 'scms-local-accepted-materials',
  components: {
    ScmsCopyBlock,
    ScmsHeadingWithLevel,
    ScmsModuleAccordionCard,
    ScmsItemList,
    ScmsSelect
  },
  mixins: [moduleMixin],
  computed: {
    ...mapGetters('moduleStore', ['findModuleByComponentName']),
    heading: {
      get () {
        return {
          text: this.page.modules[this.moduleIndex].data.headingText,
          tag: this.page.modules[this.moduleIndex].data.headingTag
        }
      },
      set (value) {
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingText',
          value: value.text
        })
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingTag',
          value: value.tag
        })
      }
    },
    ...mapModuleAttributes(['copyText', 'cards'])
  },
  methods: {
    cardItemDefault () {
      const appModule = this.findModuleByComponentName(this.$options.name)
      return deepCopy(appModule.defaults.cards[0])
    }
  }
}
</script>

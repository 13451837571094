<template>
  <scms-item-list-card @remove="$emit('remove', index)">
    <template slot='content'>
      <label class='form-group'>
        {{ areDumpsterOptionsLoaded ? 'Dumpster Size' : 'Loading Dumpter Sizes' }}
        <select class='form-control' v-model='selectedDumpster' @change='updateRelatedDumpsterSize' v-if='areDumpsterOptionsLoaded'>
          <optgroup v-for='(dumpsterCategory, categoryName) in availableDumpsters' :key='categoryName' :label='categoryName.toLowerCase().replace("_"," ")'>
            <option v-for='availableDumpster in dumpsterCategory' :key='availableDumpster.id' :value='`${categoryName}:${availableDumpster.id}`'>
              {{ availableDumpster.title }}
            </option>
          </optgroup>
        </select>
      </label>
      <fieldset class='form-group'>
        <legend>Check the information you want included:</legend>
        <div class='form-check form-check-inline'>
          <label class='form-check-label'>
            <input class='form-check-input' type='checkbox' v-model='showAverageCost'>
            Average Cost
          </label>
        </div>
        <div class='form-check form-check-inline'>
          <label class='form-check-label'>
            <input class='form-check-input' type='checkbox' v-model='showGeneralWeightLimit'>
            Weight Limit (general)
          </label>
        </div>
        <div class='form-check form-check-inline'>
          <label class='form-control-label'>
            <input class='form-check-input' type='checkbox' v-model='showHeavyWeightLimit'>
            Weight Limit (heavy)
          </label>
        </div>
        <div class='form-check form-check-inline'>
          <label class='form-control-label'>
            <input class='form-check-input' type='checkbox' v-model='showDimensions'>
            Dimensions
          </label>
        </div>
      </fieldset>
      <scms-copy-block v-model='copyText' :suggested-text-length='meta.suggestedCopyTextLength'/>
      <fieldset class='form-group'>
        <legend>Check the CTA button you want included:</legend>
        <label>
          <input type='radio' id='callToOrder' value='callToOrder' v-model='showCtaButton' class='mr-2' checked>
          Call to Order
        </label>
        <label>
          <input type='radio' id='learnMore' value='learnMore' v-model='showCtaButton' class='mx-2'>
          Learn More
        </label>
      </fieldset>
        <div v-if="this.showCtaButton === 'callToOrder'">
          <p>Working Hours</p>
          <div class="form-group row container">
            <div class="col-12">
              <ScmsCopyBlock label="Button Text" class="mb-1" v-model="workingHoursButtonText"></ScmsCopyBlock>
            </div>
            <label class="col-12">
              Button Destination
              <input class="form-control" type="text" v-model="workingHoursButtonDestination">
            </label>
          </div>
          <p>After Working Hours</p>
          <div class="form-group row container">
            <div class="col-12">
              <ScmsCopyBlock label="Button Text" class="mb-1" v-model="afterHoursButtonText"></ScmsCopyBlock>
            </div>
            <label class="col-12">
              Button Destination
              <input class="form-control" type="text" v-model="afterHoursButtonDestination">
            </label>
          </div>
        </div>
    </template>
  </scms-item-list-card>
</template>

<script>
import { moduleListItemMixin, ScmsItemListCard, ScmsCopyBlock, groupBy } from '@budgetdumpster/scms-ui'
import { dumpsterSizeService } from '../../services/DumpsterSizeService'

export default {
  name: 'scms-related-product-list-item',
  components: {
    ScmsCopyBlock,
    ScmsItemListCard
  },
  mixins: [moduleListItemMixin],
  created () {
    dumpsterSizeService.getDumpsterSizes().then(response => {
      this.availableDumpsters = groupBy(response, 'category')
      this.areDumpsterOptionsLoaded = true
      // Ensure relationships contains only the IDs when saving since retreiving that data to edit a page pulls in the full dumpster data
      this.updateRelatedDumpsterSize()
    }).catch(() => {
      console.error('Failed to retrieve dumpster sizes')
    })
  },
  data () {
    return {
      availableDumpsters: {},
      areDumpsterOptionsLoaded: false
    }
  },
  methods: {
    updateRelatedDumpsterSize () {
      if (!this.selectedDumpster) return
      const sizeListMap = { 'TEMPORARY': 'temporaryDumpsterSizes', 'PERMANENT': 'permanentDumpsterSizes', 'SPECIAL_USE': 'specialUseDumpsterSizes' }
      const [selectedCategory, selectedId] = this.selectedDumpster.split(':')
      this.relationship = { [sizeListMap[selectedCategory]]: [selectedId] }
    }
  },
  computed: {
    selectedDumpster: {
      get () {
        return this.item.selectedDumpster
      },
      set (value) {
        this.item.selectedDumpster = value
        this.$emit('update', this.index, this.item)
      }
    },
    relationship: {
      get () {
        return this.item.relationship
      },
      set (value) {
        this.item.relationship = value
        this.$emit('update', this.index, this.item)
      }
    },
    showAverageCost: {
      get () {
        return this.item.showAverageCost
      },
      set (value) {
        this.item.showAverageCost = value
        this.$emit('update', this.index, this.item)
      }
    },
    showGeneralWeightLimit: {
      get () {
        return this.item.showGeneralWeightLimit
      },
      set (value) {
        this.item.showGeneralWeightLimit = value
        this.$emit('update', this.index, this.item)
      }
    },
    showHeavyWeightLimit: {
      get () {
        return this.item.showHeavyWeightLimit
      },
      set (value) {
        this.item.showHeavyWeightLimit = value
        this.$emit('update', this.index, this.item)
      }
    },
    showDimensions: {
      get () {
        return this.item.showDimensions
      },
      set (value) {
        this.item.showDimensions = value
        this.$emit('update', this.index, this.item)
      }
    },
    copyText: {
      get () {
        return this.item.copyText
      },
      set (value) {
        this.item.copyText = value
        this.$emit('update', this.index, this.item)
      }
    },
    showCtaButton: {
      get () {
        return this.item.showCtaButton
      },
      set (value) {
        this.item.showCtaButton = value
        this.$emit('update', this.index, this.item)
      }
    },
    workingHoursButtonText: {
      get () {
        return this.item.workingHoursButtonText
      },
      set (value) {
        this.item.workingHoursButtonText = value
        this.$emit('update', this.index, this.item)
      }
    },
    workingHoursButtonDestination: {
      get () {
        return this.item.workingHoursButtonDestination
      },
      set (value) {
        this.item.workingHoursButtonDestination = value
        this.$emit('update', this.index, this.item)
      }
    },
    afterHoursButtonText: {
      get () {
        return this.item.afterHoursButtonText
      },
      set (value) {
        this.item.afterHoursButtonText = value
        this.$emit('update', this.index, this.item)
      }
    },
    afterHoursButtonDestination: {
      get () {
        return this.item.afterHoursButtonDestination
      },
      set (value) {
        this.item.afterHoursButtonDestination = value
        this.$emit('update', this.index, this.item)
      }
    }
  }
}
</script>

import { apolloProvider, omit } from '@budgetdumpster/scms-ui'
import gql from 'graphql-tag'

export const dumpsterSizeService = {
  getDumpsterSizes () {
    return apolloProvider.defaultClient.query({
      query: gql`
        query dumpsterSizes {
          dumpsterSizes{
            id
            createdAt
            updatedAt
            category
            title
            minimumWeightGeneral
            weightLimitGeneral
            minimumWeightHeavy
            weightLimitHeavy
            uom
            dimensions
            averageCost
            bestFor
            smallImageSrc
            mediumImageSrc
            largeImageSrc
            smallWebpImageSrc
            mediumWebpImageSrc
            largeWebpImageSrc
            imageAlt
            imageTitle
            pageUrl
          }
        }
      `
    }).then(response => response.data.dumpsterSizes)
      .catch(error => console.error(error))
  },
  getDumpsterSizeByIdAndCategory (id, category) {
    const formattedCategory = category.toUpperCase().replace('-', '_')
    return apolloProvider.defaultClient.query({
      query: gql`
        query dumpsterSize($id: ID!) {
          dumpsterSize(id: $id, category: ${formattedCategory}) {
            id
            createdAt
            updatedAt
            category
            title
            minimumWeightGeneral
            weightLimitGeneral
            minimumWeightHeavy
            weightLimitHeavy
            uom
            dimensions
            averageCost
            bestFor
            smallImageSrc
            mediumImageSrc
            largeImageSrc
            smallWebpImageSrc
            mediumWebpImageSrc
            largeWebpImageSrc
            imageAlt
            imageTitle
            pageUrl
          }
        }
      `,
      variables: {
        id: id
      }
    }).then(response => response.data.dumpsterSize)
      .catch(error => console.error(error))
  },
  saveDumpsterSize (attributes) {
    const formattedCategory = attributes.category.toUpperCase().replace('-', '_')
    return apolloProvider.defaultClient.mutate({
      mutation: gql`
        mutation createDumpsterSize($input: DumpsterSizeInput!) {
          createDumpsterSize(category: ${formattedCategory}, input: $input) {
            id
            createdAt
            updatedAt
            category
            title
            minimumWeightGeneral
            weightLimitGeneral
            minimumWeightHeavy
            weightLimitHeavy
            uom
            dimensions
            averageCost
            bestFor
            smallImageSrc
            mediumImageSrc
            largeImageSrc
            smallWebpImageSrc
            mediumWebpImageSrc
            largeWebpImageSrc
            imageAlt
            imageTitle
            pageUrl
          }
        }
      `,
      variables: {
        input: omit(['category'], attributes)
      }
    }).then(response => response.data.createDumpsterSize)
      .catch(error => console.error(error))
  },
  updateDumpsterSize (id, attributes) {
    const formattedCategory = attributes.category.toUpperCase().replace('-', '_')
    return apolloProvider.defaultClient.mutate({
      mutation: gql`
        mutation updateDumpsterSize($id: ID!, $input: DumpsterSizeUpdateInput!) {
          updateDumpsterSize(id: $id, category: ${formattedCategory}, input: $input) {
            id
            createdAt
            updatedAt
            category
            title
            minimumWeightGeneral
            weightLimitGeneral
            minimumWeightHeavy
            weightLimitHeavy
            uom
            dimensions
            averageCost
            bestFor
            smallImageSrc
            mediumImageSrc
            largeImageSrc
            smallWebpImageSrc
            mediumWebpImageSrc
            largeWebpImageSrc
            imageAlt
            imageTitle
            pageUrl
          }
        }
      `,
      variables: {
        id: id,
        input: omit(['id', 'category', 'createdAt', 'updatedAt', '__typename'], attributes)
      }
    }).then(response => response.data.updateDumpsterSize)
      .catch(error => console.error(error))
  },
  deleteDumpsterSize (id, category) {
    const formattedCategory = category.toUpperCase().replace('-', '_')
    return apolloProvider.defaultClient.mutate({
      mutation: gql`
        mutation deleteDumpsterSize($id: ID!) {
          deleteDumpsterSize(id: $id, category: ${formattedCategory}){
            id
            category
          }
        }
      `,
      variables: {
        id: id
      }
    }).then(response => response.data.deleteDumpsterSize)
      .catch(error => console.error(error))
  }
}

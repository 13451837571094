import ListDumpsterSizes from './views/ListDumpsterSizes'
import AddEditDumpsterSize from './views/AddEditDumpsterSize'
import ListMarkets from './views/ListMarkets'
import EditMarket from './views/edit-market/EditMarket.vue'

export const routes = [
  {
    path: '/dumpsters',
    name: 'dumpsters.list',
    meta: {
      layout: 'ScmsAppLayout'
    },
    component: ListDumpsterSizes
  },
  {
    path: '/dumpsters/create',
    name: 'dumpsters.create',
    meta: {
      layout: 'ScmsAppLayout'
    },
    component: AddEditDumpsterSize
  },
  {
    path: '/dumpsters/:category/edit/:id',
    name: 'dumpsters.edit',
    meta: {
      layout: 'ScmsAppLayout'
    },
    component: AddEditDumpsterSize
  },
  {
    path: '/markets',
    name: 'market.list',
    meta: {
      layout: 'ScmsAppLayout'
    },
    component: ListMarkets
  },
  {
    path: '/markets/create',
    name: 'market.create',
    meta: {
      layout: 'ScmsAppLayout'
    },
    component: EditMarket
  },
  {
    path: '/markets/:id',
    name: 'market.edit',
    meta: {
      layout: 'ScmsAppLayout'
    },
    component: EditMarket
  }
]

<template>
  <scms-module-accordion-card :title="(heading.tag) ? `${moduleTitle} [${heading.tag}]` : moduleTitle">
    <template slot="component">
      <section class="section">
        <div class="section-head">
          <span class="section-head-title">Copy Section</span>
          <scms-switch-buttons v-model="copySide" left-display="L" right-display="R" left-value="Left" right-value="Right"/>
        </div>
        <div class="section-body no-gutters">
          <scms-heading-with-level v-model="heading"/>
          <scms-copy-block v-model="copyText" label="Body Copy"/>
        </div>
      </section>
      <section class="section">
        <div class="section-head">
          <div class="w-100 d-flex justify-content-between">
            <span class="section-head-title">Map Section</span>
            <scms-switch-buttons @input="copySide = $event === 'Left' ? 'Right' : 'Left'" :value="copySide === 'Left' ? 'Right' : 'Left'"
            left-display="L" right-display="R" left-value="Left" right-value="Right"/>
          </div>
          <p class="section-head__subhead">Map will automatically be popluated based on the city name. Use the zoom control below to adjust the view.</p>
        </div>
        <div class="section-body row my-4">
          <div class="col-9">
            <img :src="mapSrc" class="section-body__map">
          </div>
          <div class="col-3">
            <p class="text-center">Map Zoom</p>
            <div class="row form-group no-gutters align-items-center">
              <div class="col-4  text-center">
                <button v-on:click="decrementZoom">
                  <font-awesome-icon icon="minus" class="fa-1x text-dark"/>
                </button>
              </div>
              <div class="col-4">
                <input v-model.number="zoomLevel" :min="minZoom" :max="maxZoom" type="number" placeholder="10" class="section-body__zoom-input"/>
              </div>
              <div class="col-4 text-center">
                <button v-on:click="incrementZoom">
                  <font-awesome-icon icon="plus" class="fa-1x text-dark"/>
                </button>
              </div>
            </div>
          </div>
        </div>
        <scms-image-inputs label="Map Image of webP format" @source="webPImageSrc = $event" :image="{ source: webPImageSrc }" :hide-alt="true" class="w-100 mt-4 ml-3" />
        <div class="col-12">
          <p>Working Hours</p>
          <div class="form-group row container">
            <div class="col-12">
              <ScmsCopyBlock label="Button Text" class="mb-1" v-model="workingHoursButtonText"></ScmsCopyBlock>
            </div>
            <label class="col-12">
              Button Destination
              <input class="form-control" type="text" v-model="workingHoursButtonDestination">
            </label>
          </div>
          <p>After Working Hours</p>
          <div class="form-group row container">
            <div class="col-12">
              <ScmsCopyBlock label="Button Text" class="mb-1" v-model="afterHoursButtonText"></ScmsCopyBlock>
            </div>
            <label class="col-12">
              Button Destination
              <input class="form-control" type="text" v-model="afterHoursButtonDestination">
            </label>
          </div>
        </div>
      </section>
    </template>
    <template slot="component-styles">
      <div class="row">
        <scms-select class="col-4" v-if="moduleTopPaddingOptions" v-model="paddingTop"
          :options="moduleTopPaddingOptions" label="Top Padding" :should-return-key="true"/>
        <scms-select class="col-4" v-if="moduleBottomPaddingOptions" v-model="paddingBottom"
          :options="moduleBottomPaddingOptions" label="Bottom Padding" :should-return-key="true"/>
      </div>
    </template>
    <template slot="component-footer">
      <label class="col-6">
        Module Unique ID Name
        <input type="text" class="form-control" v-model="moduleId">
      </label>
    </template>
  </scms-module-accordion-card>
</template>
<script>
import { moduleMixin, mapModuleAttributes, ScmsHeadingWithLevel, ScmsCopyBlock, ScmsModuleAccordionCard, ScmsSelect, ScmsSwitchButtons, ScmsImageInputs } from '@budgetdumpster/scms-ui'

export default {
  name: 'scms-two-column-map-copy',
  components: {
    ScmsSelect,
    ScmsHeadingWithLevel,
    ScmsCopyBlock,
    ScmsModuleAccordionCard,
    ScmsSwitchButtons,
    ScmsImageInputs
  },
  mixins: [moduleMixin],
  data () {
    return {
      minZoom: 1,
      maxZoom: 22
    }
  },
  computed: {
    mapSrc () {
      const { name, state } = this.page
      const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY
      return `https://maps.googleapis.com/maps/api/staticmap?center=${name},${state}&zoom=${this.zoomLevel}&size=700x400&scale=1&maptype=roadmap&key=${apiKey}`
    },
    heading: {
      get () {
        return {
          text: this.page.modules[this.moduleIndex].data.headingText,
          tag: this.page.modules[this.moduleIndex].data.headingTag
        }
      },
      set (value) {
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingText',
          value: value.text
        })
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingTag',
          value: value.tag
        })
      }
    },
    ...mapModuleAttributes(['copyText', 'zoomLevel', 'copySide', 'workingHoursButtonText', 'workingHoursButtonDestination', 'afterHoursButtonText', 'afterHoursButtonDestination', 'webPImageSrc'])
  },
  methods: {
    incrementZoom () {
      const zoom = this.zoomLevel + 1

      if (zoom <= this.maxZoom) {
        this.zoomLevel = zoom
      }
    },
    decrementZoom () {
      const zoom = this.zoomLevel - 1

      if (zoom >= this.minZoom) {
        this.zoomLevel = zoom
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';

  .section {
    &-head {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: map-get($spacers, 2);
      flex-wrap: wrap;

      &-title {
        font-size: $h4-font-size;
      }

      &__subhead {
        font-style: italic;
      }
    }

    &-body {
      padding: 0 map-get($spacers, 3);
      margin-bottom: map-get($spacers, 4);

      &__zoom-input {
        width: 100%;
      }

      &__map {
        max-width: 100%;
      }
    }
  }
</style>

<template>
  <scms-module-accordion-card :title="(heading.tag) ? `${moduleTitle} [${heading.tag}]` : moduleTitle">
    <template slot="component">
      <scms-heading-with-level v-model="heading"/>
      <scms-copy-block v-model="copyText" label="Body Copy" />
      <p>Image Src (1400 x 345)</p>
      <scms-image-inputs label="JPG Format" :hide-alt="true"
        @source="imageSrc = $event" :image="{ source: imageSrc }"/>
      <scms-image-inputs label="WebP Format" @alt="imageAlt = $event" @click.native="setWebPImageSrc"
        @source="webPImageSrc = $event" :image="{ alt: imageAlt, source: webPImageSrc }"/>
      <div class="form-group row">
        <fieldset class="col-12">
          <h5 class="text-uppercase">Product Section</h5>
          <p class="font-italic">Product cards will automatically populate based on market selected above. Select the product type for this module below. Local product cards will display the small image set in the dumpster editor.</p>
          <label class="form-check-inline">
            <input class="form-check-input" type="radio" value="marketTemporaryDumpsterSizes" v-model="dumpsterType" checked>
            Temporary Dumpsters
          </label>
          <label class="form-check-inline">
            <input class="form-check-input" type="radio" value="marketPermanentDumpsterSizes" v-model="dumpsterType">
            Permanent Dumpsters
          </label>
        </fieldset>
        <div class="col-12">
          <p>Working Hours</p>
          <div class="form-group row container">
            <div class="col-12">
              <ScmsCopyBlock label="Button Text" class="mb-1" v-model="workingHoursButtonText"></ScmsCopyBlock>
            </div>
            <label class="col-12">
              Button Destination
              <input class="form-control" type="text" v-model="workingHoursButtonDestination">
            </label>
          </div>
          <p>After Working Hours</p>
          <div class="form-group row container">
            <div class="col-12">
              <ScmsCopyBlock label="Button Text" class="mb-1" v-model="afterHoursButtonText"></ScmsCopyBlock>
            </div>
            <label class="col-12">
              Button Destination
              <input class="form-control" type="text" v-model="afterHoursButtonDestination">
            </label>
          </div>
        </div>
      </div>
    </template>
    <template slot="component-styles">
      <h3>Styles Editor</h3>
      <div class="row">
        <scms-select class="col-4" v-model="paddingTop" :options="moduleTopPaddingOptions"
          label="Top Padding" :should-return-key="true"/>
        <scms-select class="col-4" v-model="paddingBottom" :options="moduleBottomPaddingOptions"
          label="Bottom Padding" :should-return-key="true"/>
      </div>
    </template>
    <template slot="component-data">
      <h3>Data Editor</h3>
      <div class="row">
        <div class="col-12 py-2 mx-4">
          <label>
            <input class="form-check-input" type="checkbox" v-model="showLocalSchema">
            Default Structured Data
          </label>
        </div>
      </div>
    </template>
    <template slot="component-footer">
      <label class="col-6">
        Module Unique ID Name
        <input type="text" class="form-control" v-model="moduleId" placeholder="auto-populated-id-name">
      </label>
    </template>
  </scms-module-accordion-card>
</template>

<script>
import { moduleMixin, mapModuleAttributes, ScmsModuleAccordionCard, ScmsCopyBlock, ScmsHeadingWithLevel, ScmsSelect, ScmsImageInputs, getWebPImageSrc } from '@budgetdumpster/scms-ui'

export default {
  name: 'scms-product-cards-local',
  components: {
    ScmsCopyBlock,
    ScmsModuleAccordionCard,
    ScmsHeadingWithLevel,
    ScmsSelect,
    ScmsImageInputs
  },
  mixins: [moduleMixin],
  mounted () {
    if (this.imageSrc.length) {
      this.webPImageSrc = getWebPImageSrc(this.imageSrc)
    }
  },
  methods: {
    setWebPImageSrc () {
      this.webPImageSrc = getWebPImageSrc(this.imageSrc)
    }
  },
  computed: {
    dumpsterType: {
      get () {
        return this.page.modules[this.moduleIndex].data.dumpsterType
      },
      set (dumpsterType) {
        // set the type of dumpster
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'dumpsterType',
          value: dumpsterType
        })

        // set relational data
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'relationships',
          value: { [dumpsterType]: [] }
        })
      }
    },
    heading: {
      get () {
        return {
          text: this.page.modules[this.moduleIndex].data.headingText,
          tag: this.page.modules[this.moduleIndex].data.headingTag
        }
      },
      set (value) {
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingText',
          value: value.text
        })
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingTag',
          value: value.tag
        })
      }
    },
    ...mapModuleAttributes(['copyText', 'imageSrc', 'webPImageSrc', 'imageAlt', 'showLocalSchema', 'workingHoursButtonText', 'workingHoursButtonDestination', 'afterHoursButtonText', 'afterHoursButtonDestination'])
  }
}
</script>

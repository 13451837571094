import App from './App.vue'
import './main.scss'
import { apolloProvider, router, store, Vue } from '@budgetdumpster/scms-ui'
import { currentDumpsterSizeStore } from './store/CurrentDumpsterSizeStore'
import { currentMarketStore } from './store/CurrentMarketStore'
import { cityService } from './services/CityService'
import { landingPageService } from './services/LandingPageService'
import { dumpsterSizeService } from './services/DumpsterSizeService'
import { marketService } from './services/MarketService'
import { routes } from './routes'
import { configureErrors } from './utilities/Errors'
import ScmsLocalAcceptedMaterials from './components/design-system-modules/LocalAcceptedMaterials'
import ScmsLocalHero from './components/design-system-modules/LocalHero'
import ScmsLocations from './components/design-system-modules/Locations'
import ScmsProductCardsLocal from './components/design-system-modules/ProductCardsLocal'
import ScmsProductCardsMain from './components/design-system-modules/ProductCardsMain'
import ScmsProductCardsStacked from './components/design-system-modules/ProductCardsStacked'
import ScmsSingleProductFeature from './components/design-system-modules/SingleProductFeature'
import ScmsCityTab from './components/ScmsCityTab'
import ScmsRelatedProductListItem from './components/module-list-items/RelatedProductListItem'
import ScmsTwoColumnMapCopy from './components/design-system-modules/TwoColumnMapCopy'
import ScmsFullWidthForm from './components/design-system-modules/FullWidthForm'
import ScmsBreadcrumbs from './components/design-system-modules/Breadcrumbs'
import ScmsCustomHTML from './components/design-system-modules/CustomHTML'

Vue.component('scms-local-accepted-materials', ScmsLocalAcceptedMaterials)
Vue.component('scms-local-hero', ScmsLocalHero)
Vue.component('scms-locations', ScmsLocations)
Vue.component('scms-product-cards-local', ScmsProductCardsLocal)
Vue.component('scms-product-cards-main', ScmsProductCardsMain)
Vue.component('scms-product-cards', ScmsProductCardsStacked)
Vue.component('scms-single-product-feature', ScmsSingleProductFeature)
Vue.component('ScmsCityTab', ScmsCityTab)
Vue.component('scms-related-product-list-item', ScmsRelatedProductListItem)
Vue.component('scms-two-column-map-copy', ScmsTwoColumnMapCopy)
Vue.component('scms-full-width-form', ScmsFullWidthForm)
Vue.component('scms-breadcrumbs', ScmsBreadcrumbs)
Vue.component('scms-custom-html', ScmsCustomHTML)

store.registerModule('currentDumpsterSizeStore', currentDumpsterSizeStore)
store.registerModule('currentMarketStore', currentMarketStore)

// Hook for the error messages configuration
configureErrors()

store.commit('pluginStore/addPlugin', {
  pageTypes: [
    {
      name: 'cities',
      icon: 'building',
      displayName: 'City',
      pluralDisplayName: 'Cities',
      pageName: 'Local Page',
      pluralPageName: 'Local Pages',
      service: cityService,
      prefabPageAttributes: {
        title: function ({ name, state, title }) {
          if (!name || !state) return ''
          if (title && title.includes('Clone')) return title
          return `${name}, ${state}`
        }
      },
      tabs: [{
        displayName: 'City Attributes',
        name: 'city',
        show: true,
        component: 'ScmsCityTab'
      }]
    },
    {
      name: 'landingpages',
      icon: 'file',
      displayName: 'LandingPage',
      pluralDisplayName: 'LandingPages',
      pageName: 'Landing Page',
      pluralPageName: 'Landing Pages',
      service: landingPageService
    }
  ],
  resourceTypes: [
    {
      name: 'dumpsters',
      displayName: 'Dumpster',
      pluralDisplayName: 'Dumpsters',
      service: dumpsterSizeService
    },
    {
      name: 'markets',
      displayName: 'Market',
      pluralDisplayName: 'Markets',
      service: marketService
    },
    {
      name: 'cta-editor',
      displayName: 'CTA Editor',
      pluralDisplayName: 'CTA Editor',
      service: 'ctaEditorService'
    }
  ]
})

router.addRoutes(routes)

// Turns off console warning about using vue in dev mode
Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')

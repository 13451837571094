<template>
  <div :class='[{"mt-3": valuePrefix !== "mswMultipurpose"}, "bg-light-gray border p-2"]'>
    <div class='row'>
      <h5 class='col-10'>{{ title }}</h5>
      <label class='col-2 form-check form-check-label' v-if='valuePrefix === "cdConstruction"'>
        <input type='checkbox' v-model='isConstructionSameAsMsw'>
        Same as MSW
      </label>
      <template v-if="valuePrefix !== 'specialUse'">
        <label class='col-6 nopadding'>
          Weight Limit
          <input class='form-control' type='text' v-model='weightLimit' :readonly="isReadOnly(valuePrefix === 'cdConstruction')">
        </label>
        <label class='col-6 nopadding'>
          Price (<em>Only enter three digit number. Ex: 379</em>)
          <input class='form-control' type='text' v-model='price' :readonly="isReadOnly(valuePrefix === 'cdConstruction')">
        </label>
      </template>
      <label class='col-12'>
        Terms
        <textarea class='form-control' rows='2' v-model='terms'></textarea>
      </label>
    </div>
  </div>
</template>

<script>
//  @input='emitUpdate(`${valuePrefix}WeightLimit`, $event.target.value)'
export default {
  name: 'scms-market-dumpster-details',
  props: {
    item: {
      type: Object,
      required: true
    },
    title: {
      type: String
    },
    valuePrefix: {
      type: String,
      default: ''
    }
  },
  computed: {
    isConstructionSameAsMsw: {
      get () {
        return this.item['isConstructionSameAsMsw']
      },
      set (value) {
        this.emitUpdate('isConstructionSameAsMsw', value)
      }
    },
    weightLimit: {
      get () {
        if (this.valuePrefix) return this.item[`${this.valuePrefix}WeightLimit`]
        return this.item['weightLimit']
      },
      set (value) {
        if (this.valuePrefix) {
          this.emitUpdate(`${this.valuePrefix}WeightLimit`, value)
          return
        }
        this.emitUpdate('weightLimit', value)
      }
    },
    price: {
      get () {
        if (this.valuePrefix) return this.item[`${this.valuePrefix}Price`]
        return this.item['price']
      },
      set (value) {
        if (this.valuePrefix) {
          this.emitUpdate(`${this.valuePrefix}Price`, value)
          return
        }
        this.emitUpdate('price', value)
      }
    },
    terms: {
      get () {
        if (this.valuePrefix) return this.item[`${this.valuePrefix}Terms`]
        return this.item['terms']
      },
      set (value) {
        if (this.valuePrefix) {
          this.emitUpdate(`${this.valuePrefix}Terms`, value)
          return
        }
        this.emitUpdate('terms', value)
      }
    }
  },
  methods: {
    emitUpdate (name, value) {
      let newItem = Object.assign({}, this.item)
      newItem[name] = value

      if (newItem.isConstructionSameAsMsw) {
        newItem.cdConstructionPrice = newItem.mswMultipurposePrice
        newItem.cdConstructionWeightLimit = newItem.mswMultipurposeWeightLimit
      }

      this.$emit('update', newItem)
    },
    isReadOnly (inputCanBeReadOnly) {
      return inputCanBeReadOnly && this.item.isConstructionSameAsMsw
    }
  }
}
</script>

<template>
  <scms-module-accordion-card :title="(heading.tag) ? `${moduleTitle} [${heading.tag}]` : moduleTitle">
    <template slot="component">
      <scms-heading-with-level v-model="heading"/>
      <scms-copy-block v-model="copyText"/>
      <p>Banner Image (1030 x 110)</p>
      <scms-image-inputs label="JPG Format" :hide-alt="true"
        @source="imageSrc = $event" :image="{ source: imageSrc }"/>
      <scms-image-inputs label="WebP Format" @alt="imageAlt = $event" @click.native="setWebPImageSrc"
        @source="webPImageSrc = $event" :image="{ alt: imageAlt, source: webPImageSrc }"/>
      <div v-if="allCities.length > 0">
        <label class="mr-3">
          Filter Locations
          <input class="form-control" type="text" v-model="filter" placeholder="Enter City Name">
        </label>
        <div>
          <label class="col-3 p-0 pl-2">
            <input type="checkbox" @change="selectAllHandler" v-model="selectAll"/>
            {{ selectAll ? 'Select None' : 'Select All' }}
          </label>
          <label class="col-3 p-0">
            <input type="radio" value="selected" v-model="show"/>
            Show Selected
          </label>
          <label class="col-3 p-0">
            <input type="radio" value="all" v-model="show"/>
            Show All
          </label>
        </div>
        <ul class="list row m-0 p-0 pt-2">
          <li class="col-6" v-for="city in filteredCities" :key="city.id">
            <label>
              <input :value="city.id" v-model.number="cities" type="checkbox" class="mx-2"/>
              <span>{{ city.name }}, {{ city.state }}</span>
            </label>
          </li>
        </ul>
      </div>
      <div class="form-group row mt-4">
        <label class="col-6">
          Bottom Link Text
          <input class="form-control" type="text" v-model="bottomLinkText">
        </label>
        <label class="col-6">
          Bottom Link Destination
          <input class="form-control" type="text" v-model="bottomLinkDestination">
        </label>
      </div>
    </template>
    <template slot="component-styles">
      <div class="row">
        <scms-select class="col-4" v-if="moduleMeta.backgroundColors" v-model="backgroundColor"
          :options="moduleMeta.backgroundColors" label="Background Color" :should-return-key="true"/>
        <scms-select class="col-4" v-if="moduleMeta.backgroundColors" v-model="contentBackgroundColor"
          :options="moduleMeta.backgroundColors" label="Content Background Color" :should-return-key="true"/>
      </div>
      <div class="row">
        <scms-select class="col-4" v-if="moduleTopPaddingOptions" v-model="paddingTop"
          :options="moduleTopPaddingOptions" label="Top Padding" :should-return-key="true"/>
        <scms-select class="col-4" v-if="moduleBottomPaddingOptions" v-model="paddingBottom"
          :options="moduleBottomPaddingOptions" label="Bottom Padding" :should-return-key="true"/>
      </div>
    </template>
    <template slot="component-footer">
      <label class="col-6">
        Module Unique ID Name
        <input type="text" class="form-control" v-model="moduleId">
      </label>
    </template>
  </scms-module-accordion-card>
</template>

<script>
import { mapState } from 'vuex'
import { ScmsCopyBlock, ScmsSelect, ScmsHeadingWithLevel, ScmsImageInputs, ScmsModuleAccordionCard, moduleMixin, mapModuleAttributes, getWebPImageSrc } from '@budgetdumpster/scms-ui'
import { cityService } from '../../services/CityService'

export default {
  name: 'scms-locations',
  components: {
    ScmsCopyBlock,
    ScmsHeadingWithLevel,
    ScmsModuleAccordionCard,
    ScmsSelect,
    ScmsImageInputs
  },
  mixins: [moduleMixin],
  data: function () {
    return {
      allCities: [],
      filter: '',
      selectAll: false,
      show: 'all'
    }
  },
  mounted () {
    if (this.imageSrc.length) {
      this.webPImageSrc = getWebPImageSrc(this.imageSrc)
    }
  },
  created () {
    if (!this.relationships) {
      this.relationships = {}
    }

    cityService.getAllCities()
      .then((response) => {
        this.allCities = response
      })

    if (this.relationships.cities.length !== 0) {
      this.cities = this.relationships.cities.map(city => city.id)
    }
    // perhaps add this back later and check first that relationships.cities is empty
    // this was overriding the saved ones, making it appear the wrong ones had been saved
    // if (this.page.latitude && this.page.longitude) {
    //   cityService.getWithinRadius(this.page.latitude, this.page.longitude, 20)
    //     .then((searchResults) => {
    //       this.cities = searchResults.map(searchResult => searchResult.city.id)
    //     })
    // }
  },
  methods: {
    selectAllHandler () {
      if (!this.selectAll) {
        this.relationships.cities = []
        return
      }
      this.relationships.cities = this.allCities.map(city => city.id)
    },
    setWebPImageSrc () {
      this.webPImageSrc = getWebPImageSrc(this.imageSrc)
    }
  },
  computed: {
    filteredCities () {
      let filteredCities = this.allCities.filter((city) => city.name.toLowerCase().includes(this.filter.toLowerCase()))
      if (this.show === 'selected') {
        filteredCities = filteredCities.filter((city) => this.cities.includes(Number(city.id)))
      }
      return filteredCities
    },
    heading: {
      get () {
        return {
          text: this.page.modules[this.moduleIndex].data.headingText,
          tag: this.page.modules[this.moduleIndex].data.headingTag
        }
      },
      set (value) {
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingText',
          value: value.text
        })
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingTag',
          value: value.tag
        })
      }
    },
    cities: {
      get () {
        return this.page.modules[this.moduleIndex].data.relationships.cities
      },
      set (value) {
        const relationships = this.relationships
        relationships.cities = value
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'relationships',
          value: relationships
        })
      }
    },
    ...mapModuleAttributes(['copyText', 'relationships', 'webPImageSrc', 'imageSrc', 'imageAlt', 'bottomLinkText', 'bottomLinkDestination', 'backgroundColor', 'contentBackgroundColor']),
    ...mapState('currentPageStore', ['page'])
  }
}
</script>

<style scoped lang="scss">
  @import '~@budgetdumpster/scms-ui/src/assets/scss/base/variables';

  .list {
    border: 1px solid $gray-500;
    max-height: 200px;
    min-height: 50px;
    overflow-y: scroll;
    list-style-type: none;
  }
</style>

<template>
  <div>
    <h1 class="float-left">All Dumpsters</h1>
    <router-link :to="{path: `/dumpsters/create`}" tag="button" class="float-right w-auto bg-success m-3">
      Add New Dumpster
    </router-link>
    <div class="row pt-3 dumpster-sizes-container">
      <div :class="`col-${12 / Object.keys(dumpsters).length} text-right dumpster-list`" v-for="(dumpsterCategory, categoryName) in dumpsters" :key="categoryName">
        <h5 class="py-3 mb-0 text-center">{{ categoryName.replace('_',' ') }}</h5>
        <ul v-if="dumpsterCategory.length > 0">
          <li class="py-3" v-for="currentDumpster in dumpsterCategory" :key="currentDumpster.id">
            <div class="row">
              <div class="col-4">
                <router-link :to="{path: `/dumpsters/${currentDumpster.category.toLowerCase()}/edit/${currentDumpster.id}`}" tag="button" class="bg-primary w-100">
                  Edit
                </router-link>
              </div>
              <span class=" col-4 p-0 py-2 dumpster-size">{{ currentDumpster.title }}</span>
              <div class="col-4">
                <button type="button" class="delete bg-danger w-100" @click="promptForDelete(currentDumpster.id, categoryName)">  Delete
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <scms-modal name="delete-dumpster-size-modal" title="Confirm Delete">
      <template slot="body">
        Are you sure you want to delete the dumpster size?
      </template>
      <template slot="footer">
        <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
        <button type="button" class="btn btn-primary" @click="deleteDumpsterSize">Delete</button>
      </template>
    </scms-modal>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'
import { groupBy, ScmsModal } from '@budgetdumpster/scms-ui'
import { dumpsterSizeService } from '../services/DumpsterSizeService'

export default {
  name: 'scms-dumpster-size-list',
  components: { ScmsModal },
  data () {
    return {
      dumpsters: [],
      dumpsterSizeIdToDelete: null,
      dumpsterSizeTypeToDelete: ''
    }
  },
  created () {
    dumpsterSizeService.getDumpsterSizes().then(response => {
      this.dumpsters = groupBy(response, 'category')
    }).catch(() => {
      this.notify({ type: 'danger', 'message': 'Failed to get all Dumpster Sizes' })
    })
  },
  methods: {
    promptForDelete (id, type) {
      this.dumpsterSizeIdToDelete = id
      this.dumpsterSizeTypeToDelete = type
      this.openModal({ name: 'delete-dumpster-size-modal' })
    },
    deleteDumpsterSize () {
      dumpsterSizeService.deleteDumpsterSize(this.dumpsterSizeIdToDelete, this.dumpsterSizeTypeToDelete)
        .then((response) => {
          this.dumpsters[response.category] = this.dumpsters[response.category].filter(dumpster => dumpster.id !== response.id)
        })
        .catch(() => {
          this.notify({ type: 'danger', 'message': `Failed to delete Dumpster Size with ID ${this.dumpsterSizeIdToDelete}` })
        })
      this.closeModal()
    },
    ...mapMutations('modalStore', ['closeModal', 'openModal']),
    ...mapActions('notificationStore', {
      notify: 'add'
    })
  }
}
</script>
<style scoped lang="scss">
  @import '~@budgetdumpster/scms-ui/src/assets/scss/base/variables';

  .dumpster-sizes-container {
    width: 80%;

    .dumpster-list {
      h5 {
        background-color: $secondary;
        color: white;
      }

      ul {
        padding: 0;
        text-align: center;

        li {
          list-style-type: none;
          font-size: 16px;
          border-bottom: 1px solid $secondary;
        }
      }

      .dumpster-size {
        font-size: 16px;
      }
    }
  }
</style>

import { PageTypeService } from '@budgetdumpster/scms-ui'

const landingpageFields = `
  id
  createdAt
  updatedAt
  type
  title
  pageUrl
  phoneNumber
  customHeaderName
  customFooterName
  modules
  live
  editedBy
  metadataTitle
  metadataKeywords
  metadataDescription
  metadataModifiedDate
  metadataPublishedDate
  metadataTwTitle
  metadataTwUrl
  metadataTwCard
  metadataTwSite
  metadataTwDescription
  metadataTwImage
  metadataOgTitle
  metadataOgUrl
  metadataOgType
  metadataOgDescription
  metadataOgImage
  metadataCanonical
  metadataRobots
  showMobileHeaderCta
`

const landingPageService = Object.create(PageTypeService)
landingPageService.inputType = 'LandingPage'
landingPageService.paginateAction = 'landingpagesConnection'
landingPageService.findAction = 'landingpage'
landingPageService.createAction = 'createLandingPage'
landingPageService.updateAction = 'updateLandingPage'
landingPageService.cloneAction = 'cloneLandingPage'
landingPageService.previewAction = 'createPreview'
landingPageService.deleteAction = 'deleteLandingPage'
landingPageService.type = 'LANDING_PAGE'

landingPageService.paginationFields = landingpageFields
landingPageService.pageFields = landingpageFields

export { landingPageService }

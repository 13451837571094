export const googlePlacesService = {
  isGoogleReady () {
    return Boolean(window && window.google && window.google.maps && window.google.maps.places)
  },
  isStatusOk (status) {
    return window.google.maps.places.PlacesServiceStatus.OK === status
  },
  waitForGoogle () {
    return new Promise((resolve) => {
      const waitForGoogleInterval = setInterval(() => {
        if (this.isGoogleReady()) {
          clearInterval(waitForGoogleInterval)
          return resolve()
        }
      }, 300)
    })
  },
  getCoordinates (inputEl, query, fields = ['geometry']) {
    return this.waitForGoogle().then(() => {
      return new Promise((resolve, reject) => Promise
        .resolve(new window.google.maps.places.PlacesService(inputEl))
        .then(service => {
          return service.findPlaceFromQuery(({ query, fields }), (results, status) => {
            if (!results) {
              resolve([null, null])
              return
            }

            const [coordinates] = results

            if (!this.isStatusOk(status) || !coordinates) {
              reject(new Error('Could not retrieve coordinates from google'))
            }

            resolve([coordinates.geometry.location.lat(), coordinates.geometry.location.lng()])
          })
        })
      )
    })
  }
}

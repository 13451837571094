import { ScmsSelect, ScmsModuleAccordionCard, omit, groupBy, deepCopy } from '@budgetdumpster/scms-ui'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { marketService } from '../../services/MarketService'
import { dumpsterSizeService } from '../../services/DumpsterSizeService'
import ScmsMarketDumpsterDetails from '../../components/MarketDumpsterDetails'

const digitRegex = /\d{1,2}/g
const dumpsterYardageSort = (a, b) => {
  return a.dumpsterSize.title.match(digitRegex) - b.dumpsterSize.title.match(digitRegex)
}

export default {
  name: 'scms-edit-market',
  components: {
    ScmsSelect,
    ScmsModuleAccordionCard,
    ScmsMarketDumpsterDetails
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.prepareMarket())
  },
  created () {
    dumpsterSizeService.getDumpsterSizes().then(response => {
      this.availableDumpsters = groupBy(response, 'category')
    }).catch(() => {
      console.error('Failed to retrieve dumpster sizes')
    })
  },
  data () {
    return {
      isCreateView: false,
      isMarketLoaded: false,
      availableDumpsters: {},
      dumpsterToAdd: ''
    }
  },
  methods: {
    ...mapMutations('currentMarketStore', ['addDumpsterSize', 'setDumpsterSizes', 'setAttribute', 'setMarket', 'resetMarket', 'setAcceptedMaterials']),
    ...mapActions('notificationStore', {
      notify: 'add'
    }),
    prepareMarket () {
      const marketId = this.$route.params.id
      this.isCreateView = !marketId

      if (!this.isCreateView) {
        return marketService.find(marketId)
          .then((market) => {
            this.isMarketLoaded = true
            if (!market) return
            this.setMarket(market)
          })
      }

      this.resetMarket() // Just to make sure we are on a clean market
      this.isMarketLoaded = true
      return Promise.resolve()
    },
    saveMarket () {
      let saveAction
      if (this.isCreateView) {
        saveAction = marketService.create(omit(['id', 'createdAt', 'updatedAt', '__typename'], this.apiFormattedObject))
      } else {
        saveAction = marketService.update(this.market.id, omit(['id', 'createdAt', 'updatedAt', '__typename'], this.apiFormattedObject))
      }

      saveAction
        .then((savedMarket) => {
          this.notify({ message: `Market saved successfully` })
          this.$router.push({ name: 'market.edit', params: { id: savedMarket.id } })
        })
    },
    addDumpster () {
      if (!this.dumpsterToAdd) {
        this.notify({ type: 'danger', message: `Error: Please select a dumpster to add` })
        return
      }
      const [categoryToAdd, idToAdd] = this.dumpsterToAdd.split(':')
      const dumpsterToAdd = this.availableDumpsters[categoryToAdd].filter((dumpster) => { return dumpster.id === idToAdd })[0]
      this.addDumpsterSize({ categoryName: categoryToAdd, value: { 'dumpsterSize': dumpsterToAdd } })
      this.dumpsterToAdd = ''
    },
    removeDumpster (categoryToRemove, idToRemove) {
      const sizeListMap = { 'TEMPORARY': this.temporaryDumpsterSizes, 'PERMANENT': this.permanentDumpsterSizes, 'SPECIAL_USE': this.specialUseDumpsterSizes }
      let newSizeList = sizeListMap[categoryToRemove].filter((dumpster) => {
        return dumpster.dumpsterSize.id !== idToRemove
      })
      this.setDumpsterSizes({ categoryName: categoryToRemove, value: newSizeList })
    },
    updateDumpster (categoryName, index, newValue) {
      const sizeListMap = { 'TEMPORARY': this.temporaryDumpsterSizes, 'PERMANENT': this.permanentDumpsterSizes, 'SPECIAL_USE': this.specialUseDumpsterSizes }
      let newSizeList = deepCopy(sizeListMap[categoryName])
      newSizeList[index] = newValue
      this.setDumpsterSizes({ categoryName: categoryName, value: newSizeList })
    }
  },
  computed: {
    filteredAvailableDumpsters: function () {
      const sizeListMap = { 'TEMPORARY': this.temporaryDumpsterSizes, 'PERMANENT': this.permanentDumpsterSizes, 'SPECIAL_USE': this.specialUseDumpsterSizes }
      return Object.keys(this.availableDumpsters).reduce((acc, category) => {
        acc[category] = this.availableDumpsters[category].filter((dumpster) => {
          return !sizeListMap[category].map(dumpster => dumpster.dumpsterSize.id).includes(dumpster.id)
        })
        return acc
      }, {})
    },
    isCallForPricing: {
      get () {
        return this.market.isCallForPricing
      },
      set (value) {
        this.setAttribute({ name: 'isCallForPricing', value: value })
      }
    },
    temporaryDumpsterSizesList: {
      get () {
        return this.temporaryDumpsterSizes.sort(dumpsterYardageSort)
      },
      set (value) {
        this.setDumpsterSizes({ categoryName: 'TEMPORARY', value: value })
      }
    },
    permanentDumpsterSizesList: {
      get () {
        return this.permanentDumpsterSizes.sort(dumpsterYardageSort)
      },
      set (value) {
        this.setDumpsterSizes({ categoryName: 'PERMANENT', value: value })
      }
    },
    specialUseDumpsterSizesList: {
      get () {
        return this.specialUseDumpsterSizes.sort(dumpsterYardageSort)
      },
      set (value) {
        this.setDumpsterSizes({ categoryName: 'SPECIAL_USE', value: value })
      }
    },
    temporaryResidentialAcceptedMaterialsList: {
      get () {
        return this.temporaryResidentialAcceptedMaterials
      },
      set (value) {
        this.setAcceptedMaterials({ name: 'temporaryResidentialAcceptedMaterials', value: value })
      }
    },
    temporaryConstructionAcceptedMaterialsList: {
      get () {
        return this.temporaryConstructionAcceptedMaterials
      },
      set (value) {
        this.setAcceptedMaterials({ name: 'temporaryConstructionAcceptedMaterials', value: value })
      }
    },
    permanentAcceptedMaterialsList: {
      get () {
        return this.permanentAcceptedMaterials
      },
      set (value) {
        this.setAcceptedMaterials({ name: 'permanentAcceptedMaterials', value: value })
      }
    },
    ...mapState('currentMarketStore', ['market', 'temporaryConstructionAcceptedMaterials', 'temporaryResidentialAcceptedMaterials', 'permanentAcceptedMaterials', 'temporaryDumpsterSizes', 'permanentDumpsterSizes', 'specialUseDumpsterSizes']),
    ...mapGetters('currentMarketStore', ['apiFormattedObject'])
  }
}

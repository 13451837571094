<template>
  <div>
    <scms-search-box resource-to-search="markets" :search-params="searchParams" @searchData="handleSearch" @clearSearch="clearSearch">
        <scms-input-with-counter class='col-2' label='Name' v-model="searchParams.name"/>
        <scms-input-with-counter class='col-2' label='Phone Number' v-model="searchParams.phoneNumber"/>
    </scms-search-box>
    <scms-resource-list
      @paginate='paginate($event)'
      :has-next-page='hasNextPage'
      :has-previous-page='hasPreviousPage'
      :next-cursor='nextCursor'
      :previous-cursor='previousCursor'
      :nodes='markets'
      display-name='Market'
      plural-display-name='Markets'
      add-new-url='/markets/create'
      :columns='{
        Name: "name",
        "Phone Number": "phoneNumber",
        "Call for Pricing": (node) => node.isCallForPricing ? "Yes" : "No"
      }'
    >
      <template v-slot:leftSideRowActions="{ node }">
        <router-link :to='{name: "market.edit", params: { id: node.id }}' tag='button' class="btn btn-sm edit-button mx-2">Edit</router-link>
      </template>
      <template v-slot:rightSideRowActions="{ node }">
        <button type="button" @click="cloneMarket(node)" class="btn btn-sm btn-success mx-2">Clone</button>
        <button type="button" @click="promptForDelete(node)" class="btn btn-sm btn-danger mx-2">
          Delete
        </button>
      </template>
    </scms-resource-list>
    <scms-modal name="delete-market-modal" title="Confirm Delete">
      <template slot="body">
        Are you sure you want to delete the market?
      </template>
      <template slot="footer">
        <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
        <button type="button" class="btn btn-primary" @click="deleteMarket">Delete</button>
      </template>
    </scms-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { omit, ScmsModal, ScmsResourceList, ScmsSearchBox, ScmsInputWithCounter } from '@budgetdumpster/scms-ui'
import { marketService } from '../services/MarketService'

export default {
  name: 'scms-all-markets',
  components: {
    ScmsResourceList,
    ScmsModal,
    ScmsSearchBox,
    ScmsInputWithCounter
  },
  data () {
    return {
      marketToDelete: null,
      markets: [],
      hasPreviousPage: false,
      hasNextPage: false,
      nextCursor: null,
      previousCursor: null,
      searchParams: {
        name: '',
        phoneNumber: ''
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.paginate())
  },
  methods: {
    paginate (cursor = undefined, searchParams = this.searchParams) {
      return marketService.paginate(cursor, searchParams)
        .then((response) => {
          this.markets = response.edges.map((edge) => edge.node)
          this.nextCursor = response.pageInfo.nextCursor
          this.previousCursor = response.pageInfo.previousCursor
          this.hasNextPage = response.pageInfo.hasNextPage
          this.hasPreviousPage = response.pageInfo.hasPreviousPage
        })
        .catch(() => this.notify({ type: 'danger', message: `Failed to retrieve Markets` }))
    },
    promptForDelete (market) {
      this.marketToDelete = market
      this.openModal({ name: 'delete-market-modal' })
    },
    deleteMarket () {
      this.closeModal()
      return marketService.delete(this.marketToDelete.id)
        .then(() => {
          this.markets = this.markets.filter((market) => market.id !== this.marketToDelete.id)
          this.notify({ message: 'Market Deleted' })
        })
        .catch(() => {
          this.notify({ type: 'danger', message: 'Error: Failed to delete market' })
        })
    },
    cloneMarket (market) {
      let marketToClone
      return marketService.find(market.id)
        .then((market) => {
          marketToClone = market
          const trimmedMarket = omit(['id', 'updatedAt', 'createdAt', '__typename', 'temporaryDumpsterSizes', 'permanentDumpsterSizes', 'specialUseDumpsterSizes'], marketToClone)
          const dumpsterSizes = [marketToClone.temporaryDumpsterSizes, marketToClone.permanentDumpsterSizes, marketToClone.specialUseDumpsterSizes]
            .map(relatedDumpsterSizes => relatedDumpsterSizes.map((relatedDumpsterSize) => {
              return {
                ...omit(['dumpsterSize', '__typename'], relatedDumpsterSize), dumpsterSizeId: relatedDumpsterSize.dumpsterSize.id
              }
            }))
          const [temporaryDumpsterSizes, permanentDumpsterSizes, specialUseDumpsterSizes] = dumpsterSizes
          trimmedMarket.name = `${marketToClone.name} Copy`
          return marketService.create({
            ...trimmedMarket,
            temporaryDumpsterSizes,
            permanentDumpsterSizes,
            specialUseDumpsterSizes
          })
        })
        .then((clonedMarket) => {
          this.markets.unshift(clonedMarket)
          this.notify({ message: `Successfully cloned market "${marketToClone.name}"` })
        })
        .catch(() => this.notify({ type: 'danger', message: `Failed to clone market ${marketToClone.name}` }))
    },
    handleSearch (searchParams) {
      this.paginate(undefined, searchParams)
    },
    clearSearch () {
      this.searchParams = {
        name: '',
        phoneNumber: ''
      }
      this.paginate(undefined, this.searchParams)
    },
    ...mapActions('notificationStore', {
      notify: 'add'
    }),
    ...mapMutations('modalStore', ['closeModal', 'openModal'])
  }
}
</script>

<style scoped lang="scss">
  @import '~@budgetdumpster/scms-ui/src/assets/scss/base/variables';

  .edit-button {
    background-color: $dark-blue;
    color: $light-gray;

    &:hover {
      background-color: darken($dark-blue, 10);
    }
  }
</style>

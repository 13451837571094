<template>
    <div class="tabcontent p-3">
      <label>
        City Name
        <input class="form-control" v-model="page.name" ref="name" @keyup="autocompleteCoordinates($refs, `${page.name}, ${page.state}`, setAttribute)"/>
      </label>
      <label>
        Address
        <input class="form-control" v-model="page.address" />
      </label>
      <scms-select class="w-50 pr-2" v-model="page.state" :options="getStates()" label="State"
        :should-return-key="true"  @input="autocompleteCoordinates($refs, `${page.name}, ${page.state}`, setAttribute)"/>
      <scms-select class="w-50 pl-2" v-model="page.country" :options="{ usa: 'USA', canada: 'Canada' }" label="Country"
        :should-return-key="true"/>
      <div class="row">
        <fieldset class="form-group ml-3">
          <legend class="col-form-label">Is {{ page.name || 'this' }} a hub city?</legend>
          <label class="form-check-inline w-auto">
            <input class="form-check-input" type="radio" name="hubcity" :value="true" v-model="page.isHub"/>
            Yes
          </label>
          <label class="form-check-inline w-auto">
            <input class="form-check-input" type="radio" name="hubcity" :value="false" v-model="page.isHub"/>
            No
          </label>
        </fieldset>
      </div>
      <label v-if="page.isHub">
        Hub City Radius
        <input class="form-control" type="number" v-model.number="page.hubRadius"/>
      </label>
      <label class="w-50 pr-2">
        Latitude
        <input class="form-control" type="number" step="any" min="-90" max="90" v-model.number="page.latitude"/>
      </label>
      <label class="w-50 pl-2">
        Longitude
        <input class="form-control" type="number" step="any" min="-180" max="180" v-model.number="page.longitude"/>
      </label>
      <scms-select v-if="this.areMarketsLoaded" v-model="marketId" :options="this.availableMarkets" label="Select Market"
        :should-return-key="true" @change.native="getPhoneNumber"/>
      <label class="pt-2">
        <input type="checkbox" class="mr-1" v-model="page.isCallForPricing"/> Switch to Call for Pricing
      </label>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import StatesList from './statelist'
import { ScmsSelect } from '@budgetdumpster/scms-ui'
import { marketService } from '../services/MarketService'
import { googlePlacesService } from '../services/GooglePlacesService'
import debounce from 'lodash.debounce'

export default {
  name: 'ScmsCityTabs',
  components: { ScmsSelect },
  created () {
    marketService.findAll().then((markets) => {
      if (this.marketId) {
        let { phoneNumber } = markets.find((market) => market.id === this.marketId)
        this.setAttribute({ name: 'phoneNumber', value: phoneNumber })
        phoneNumber = null
      }
      markets.map(market => {
        this.availableMarkets = { ...this.availableMarkets, [market.id]: market.name }
      })
      this.areMarketsLoaded = true
    })
  },
  data () {
    return {
      availableMarkets: {},
      areMarketsLoaded: false
    }
  },
  methods: {
    getPhoneNumber: function () {
      marketService.find(this.marketId).then((market) => {
        this.setAttribute({ name: 'phoneNumber', value: market.phoneNumber })
      })
    },
    getStates () {
      return StatesList
    },
    autocompleteCoordinates: debounce((refs, value, setAttribute) => {
      googlePlacesService.getCoordinates(refs.name, value).then(data => {
        const [lat, lng] = data

        if (lat) {
          setAttribute({ name: 'latitude', value: lat })
        }

        if (lng) {
          setAttribute({ name: 'longitude', value: lng })
        }
      })
    }, 300),
    ...mapMutations('currentPageStore', ['setAttribute'])
  },
  computed: {
    ...mapState('currentPageStore', ['page']),
    marketId: {
      get () {
        if (this.page.hasOwnProperty('market') && this.page.market.hasOwnProperty('id')) return this.page.market.id
        return ''
      },
      set (value) {
        this.setAttribute({ name: 'market', value: { id: value } })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@budgetdumpster/scms-ui/src/assets/scss/base/variables';

    .tabcontent {
      border: 1px solid $light;
      background-color: $light;

      .form-control {
        font-size: inherit;

        &::placeholder {
          color: $dark;
        }
      }

      select {
        background-color: $light;
      }

      label {
        width: 100%;
      }
    }

</style>

import { apolloProvider } from '@budgetdumpster/scms-ui'
import gql from 'graphql-tag'

const dumpsterSizeFields = `
  id
  createdAt
  updatedAt
  category
  title
  weightLimitGeneral
  weightLimitHeavy
  dimensions
  averageCost
  smallImageSrc
  mediumImageSrc
  largeImageSrc
  imageAlt
  imageTitle
  pageUrl
`

const marketFields = `
  id
  createdAt
  updatedAt
  name
  phoneNumber
  timeZone
  isCallForPricing
  temporaryResidentialDisclaimer
  temporaryConstructionDisclaimer
  temporaryDisclaimer
  permanentDisclaimer
  temporaryConstructionAcceptedMaterials
  temporaryResidentialAcceptedMaterials
  permanentAcceptedMaterials
  permanentDumpsterSizes {
    dumpsterSize {
      ${dumpsterSizeFields}
    }
  }
  temporaryDumpsterSizes {
    dumpsterSize {
      ${dumpsterSizeFields}
    }
    mswMultipurposeWeightLimit
    mswMultipurposePrice
    mswMultipurposeTerms
    cdConstructionWeightLimit
    cdConstructionPrice
    cdConstructionTerms
    specialUseTerms
    isConstructionSameAsMsw
  }
  specialUseDumpsterSizes {
    dumpsterSize {
      ${dumpsterSizeFields}
    }
    weightLimit
    price
    terms
  }
`

export const marketService = {
  paginate (after = undefined, searchParams = {}) {
    return apolloProvider.defaultClient.query({
      query: gql`
        query marketsConnection($after: String, $searchParams: MarketSearchParams) {
          marketsConnection(size: 20, after: $after, searchParams: $searchParams) {
            edges {
              node {
                id
                name
                phoneNumber
                isCallForPricing
              }
              cursor
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              previousCursor
              nextCursor
            }
          }
        }
      `,
      variables: {
        after,
        searchParams
      }
    }).then(results => results.data.marketsConnection)
      .catch((error) => {
        console.error('Failed to paginate markets: ', error)
        throw error
      })
  },
  delete (id) {
    return apolloProvider.defaultClient.mutate({
      mutation: gql`
        mutation deleteMarket($id: ID!) {
          deleteMarket (id: $id) {
            id
          }
        }
      `,
      variables: {
        id
      }
    }).then((result) => result.data.deleteMarket)
      .catch((error) => {
        console.error('Failed to delete market: ', error)
        throw error
      })
  },
  find (id) {
    return apolloProvider.defaultClient.query({
      query: gql`
        query findMarket ($id: ID!) {
          market (id: $id) {
            ${marketFields}
          }
        }
      `,
      variables: {
        id
      }
    }).then((result) => result.data.market)
      .catch((error) => {
        console.error('Failed to find market: ', error)
        throw error
      })
  },
  findAll () {
    return apolloProvider.defaultClient.query({
      query: gql`
        query getAllMarkets {
          markets{
            id
            name
            phoneNumber
          }
        }
      `
    }).then((result) => result.data.markets)
      .catch((error) => {
        console.error('Failed to find all market: ', error)
        throw error
      })
  },
  create (market) {
    return apolloProvider.defaultClient.mutate({
      mutation: gql`
        mutation createMarket($marketInput: MarketCreateInput!) {
          createMarket(input: $marketInput) {
            ${marketFields}
          }
        }
      `,
      variables: {
        marketInput: market
      }
    }).then((result) => result.data.createMarket)
      .catch((error) => {
        console.error('Failed to create market: ', error)
        throw error
      })
  },
  update (id, market) {
    return apolloProvider.defaultClient.mutate({
      mutation: gql`
        mutation updateMarket($id: ID!, $marketInput: MarketUpdateInput!) {
          updateMarket(id: $id, input: $marketInput) {
            ${marketFields}
          }
        }
      `,
      variables: {
        id,
        marketInput: market
      }
    }).then((result) => result.data.updateMarket)
      .catch((error) => {
        console.error('Failed to update market: ', error)
        throw error
      })
  }
}

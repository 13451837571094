import { apolloProvider, PageTypeService } from '@budgetdumpster/scms-ui'
import gql from 'graphql-tag'

const cityFields = `
  id
  createdAt
  updatedAt
  title
  type
  pageUrl
  phoneNumber
  modules
  live
  market {
    id
  }
  editedBy
  metadataTitle
  metadataKeywords
  metadataDescription
  metadataTwTitle
  metadataTwUrl
  metadataTwCard
  metadataTwSite
  metadataTwDescription
  metadataTwImage
  metadataOgTitle
  metadataOgUrl
  metadataOgType
  metadataOgDescription
  metadataOgImage
  metadataCanonical
  metadataRobots
  metadataPublishedDate
  metadataModifiedDate
  name
  state
  address
  country
  isHub
  hubRadius
  latitude
  longitude
  isCallForPricing
  customHeaderName
  customFooterName
  showMobileHeaderCta
`

const cityResult = `
  id
  name
  state
`

const cityService = Object.create(PageTypeService)
cityService.inputType = 'City'
cityService.paginateAction = 'citiesConnection'
cityService.findAction = 'city'
cityService.createAction = 'createCity'
cityService.updateAction = 'updateCity'
cityService.cloneAction = 'cloneCity'
cityService.previewAction = 'createPreview'
cityService.deleteAction = 'deleteCity'
cityService.type = 'CITY'

cityService.paginationFields = cityFields
cityService.pageFields = cityFields

cityService.beforeDataSend = (cityFromStore) => {
  const newCity = { ...cityFromStore, marketId: cityFromStore.market.id }
  delete newCity.market
  return newCity
}

cityService.getAllCities = function () {
  return apolloProvider.defaultClient.query({
    query: gql`
      query cities {
        cities {
          ${cityResult}
        }
      }
    `
  }).then(response => response.data.cities)
    .catch(error => console.error(error))
}

cityService.getWithinRadius = function (lat, long, radius) {
  return apolloProvider.defaultClient.query({
    query: gql`
      query searchByCoordinates($lat: Float!, $long: Float!, $radius: Float!) {
        searchByCoordinates(lat: $lat, lng: $long, radius: $radius) {
          city {
            ${cityResult}
          }
        }
      }
    `,
    variables: {
      lat: lat,
      long: long,
      radius: radius
    }
  }).then(response => response.data.searchByCoordinates)
    .catch(error => console.error(error))
}

export { cityService }

<template>
  <scms-module-accordion-card :title="moduleTitle" :id-name="$options.name" v-model="moduleId">
    <template slot="component">
      <scms-heading-with-level v-model="heading"/>
      <div class="form-group row">
        <label class="col-12 mb-3">
          Above Button CTA Copy
          <input type="text" class="form-control" v-model="ctaCopy">
        </label>
        <label class="col-12">
          Address
          <input type="text" class="form-control" v-model="address">
        </label>
      </div>
      <p>Desktop Hero Image Background (1440 x 390)</p>
      <scms-image-inputs class="ml-2" label="JPG Format" @source="desktopImageSrc = $event"
         :image="{ source: desktopImageSrc || imageSrc }" :hide-alt="true"/>
      <scms-image-inputs class="ml-2" label="WebP Format" @source="desktopWebPImageSrc = $event" @click.native="setDesktopWebPImageSrc"
        :image="{ source: desktopWebPImageSrc || imageSrc }" :hide-alt="true"/>
      <p>Tablet Hero Image Background (960 x 390)</p>
      <scms-image-inputs class="ml-2" label="JPG Format" @source="tabletImageSrc = $event"
        :image="{ source: tabletImageSrc }" :hide-alt="true"/>
      <scms-image-inputs class="ml-2" label="WebP Format" @source="tabletWebPImageSrc = $event" @click.native="setTabletWebPImageSrc"
        :image="{ source: tabletWebPImageSrc }" :hide-alt="true"/>
      <p>Mobile Hero Image Background (480 x 600)</p>
      <scms-image-inputs class="ml-2" label="JPG Format" @source="mobileImageSrc = $event"
        :image="{ source: mobileImageSrc }" :hide-alt="true"/>
      <scms-image-inputs class="ml-2" label="WebP Format" @source="mobileWebPImageSrc = $event" @click.native="setMobileWebPImageSrc"
        :image="{ alt: imageAlt, source: mobileWebPImageSrc }" @alt="imageAlt = $event"/>
    </template>
    <template slot="component-footer">
      <label class="col-6">
        Module Unique ID Name
        <input type="text" class="form-control" v-model="moduleId">
      </label>
    </template>
  </scms-module-accordion-card>
</template>

<script>
import { moduleMixin, mapModuleAttributes, ScmsModuleAccordionCard, ScmsHeadingWithLevel, ScmsImageInputs, getWebPImageSrc } from '@budgetdumpster/scms-ui'

export default {
  name: 'scms-local-hero',
  components: {
    ScmsModuleAccordionCard,
    ScmsHeadingWithLevel,
    ScmsImageInputs
  },
  mixins: [moduleMixin],
  mounted () {
    if (this.desktopImageSrc.length && !this.desktopWebPImageSrc) {
      this.desktopWebPImageSrc = getWebPImageSrc(this.desktopImageSrc)
    }
    if (this.tabletImageSrc.length && !this.tabletWebPImageSrc) {
      this.tabletWebPImageSrc = getWebPImageSrc(this.tabletImageSrc)
    }
    if (this.mobileImageSrc.length && !this.mobileWebPImageSrc) {
      this.mobileWebPImageSrc = getWebPImageSrc(this.mobileImageSrc)
    }
  },
  methods: {
    setDesktopWebPImageSrc () {
      this.desktopWebPImageSrc = getWebPImageSrc(this.desktopImageSrc)
    },
    setTabletWebPImageSrc () {
      this.tabletWebPImageSrc = getWebPImageSrc(this.tabletImageSrc)
    },
    setMobileWebPImageSrc () {
      this.mobileWebPImageSrc = getWebPImageSrc(this.mobileImageSrc)
    }
  },
  computed: {
    heading: {
      get () {
        return {
          text: this.page.modules[this.moduleIndex].data.headingText,
          tag: this.page.modules[this.moduleIndex].data.headingTag
        }
      },
      set (value) {
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingText',
          value: value.text
        })
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingTag',
          value: value.tag
        })
      }
    },
    ...mapModuleAttributes(['ctaCopy', 'address', 'imageSrc', 'desktopImageSrc', 'tabletImageSrc', 'mobileImageSrc', 'desktopWebPImageSrc', 'tabletWebPImageSrc', 'mobileWebPImageSrc', 'imageAlt'])
  }
}
</script>

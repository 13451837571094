<template>
  <scms-module-accordion-card :title="(heading.tag) ? `${moduleTitle} [${heading.tag}]` : moduleTitle">
    <template slot="component">
      <section class="section">
        <div class="section-head">
          <span class="section-head-title">Copy Section</span>
          <scms-switch-buttons v-model="copySide" left-display="L" right-display="R" left-value="Left" right-value="Right"/>
        </div>
        <div class="section-body">
          <scms-heading-with-level v-model="heading"/>
          <scms-copy-block v-model="copyText" :suggested-text-range="moduleMeta.suggestedCopyTextRange"/>
        </div>
      </section>
      <section v-if="areDumpsterOptionsLoaded" class="section">
        <div class="section-head">
          <span class="section-head-title">Product Section</span>
          <scms-switch-buttons @input="copySide = $event === 'Left' ? 'Right' : 'Left'" :value="copySide === 'Left' ? 'Right' : 'Left'"
            left-display="L" right-display="R" left-value="Left" right-value="Right"/>
        </div>
        <div class="section-body">
          <label class='form-group'>
            {{ areDumpsterOptionsLoaded ? 'Dumpster Size' : 'Loading Dumpter Sizes' }}
            <select class='form-control' v-model='selectedDumpster' @change='updateRelatedDumpsterSize' v-if='areDumpsterOptionsLoaded'>
              <optgroup v-for='(dumpsterCategory, categoryName) in availableDumpsters' :key='categoryName' :label='categoryName.toLowerCase().replace("_"," ")'>
                <option v-for='availableDumpster in dumpsterCategory' :key='availableDumpster.id' :value='`${categoryName}:${availableDumpster.id}`'>
                  {{ availableDumpster.title }}
                </option>
              </optgroup>
            </select>
          </label>
          <div class="form-check my-3">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" v-model="product.showAverageCost" @change="updateProduct($event.target.value)">
              Include Average Cost Range
            </label>
          </div>
          <scms-copy-block v-model="product.copyText" label="Copy Block"/>
          <fieldset class="form-group">
            <legend>Check the CTA button you want included:</legend>
            <label>
              <input type="radio" id="callToOrder" value="callToOrder" v-model="product.showCtaButton" class="mr-2" checked>
              Call to Order
            </label>
            <label>
              <input type="radio" id="learnMore" value="learnMore" v-model="product.showCtaButton" class="mx-2">
              Learn More
            </label>
            <label>
              <input type="radio" id="searchForPricing" value="searchForPricing" v-model="product.showCtaButton" class="mx-2">
              Search For Pricing
            </label>
          </fieldset>
        </div>
      </section>
    </template>
    <template slot="component-styles">
      <scms-select class="w-25" v-if="moduleMeta.backgroundColors" v-model="backgroundColor"
        :options="moduleMeta.backgroundColors" label="Background Color" :should-return-key="true"/>
      <div class="row">
        <scms-select class="col-4" v-if="moduleTopPaddingOptions" v-model="paddingTop"
          :options="moduleTopPaddingOptions" label="Top Padding" :should-return-key="true"/>
        <scms-select class="col-4" v-if="moduleBottomPaddingOptions" v-model="paddingBottom"
          :options="moduleBottomPaddingOptions" label="Bottom Padding" :should-return-key="true"/>
      </div>
    </template>
    <template slot="component-footer">
      <label class="col-6">
        Module Unique ID Name
        <input type="text" class="form-control" v-model="moduleId">
      </label>
    </template>
  </scms-module-accordion-card>
</template>

<script>
import { moduleMixin, mapModuleAttributes, ScmsHeadingWithLevel, ScmsCopyBlock, ScmsModuleAccordionCard, ScmsSwitchButtons, ScmsSelect, groupBy } from '@budgetdumpster/scms-ui'
import { dumpsterSizeService } from '../../services/DumpsterSizeService'

export default {
  name: 'scms-single-product-feature',
  components: {
    ScmsSelect,
    ScmsSwitchButtons,
    ScmsHeadingWithLevel,
    ScmsCopyBlock,
    ScmsModuleAccordionCard
  },
  mixins: [moduleMixin],
  created () {
    dumpsterSizeService.getDumpsterSizes().then(response => {
      this.availableDumpsters = groupBy(response, 'category')
      this.areDumpsterOptionsLoaded = true
      // Ensure relationships contains only the IDs when saving since retreiving that data to edit a page pulls in the full dumpster data
      this.updateRelatedDumpsterSize()
    }).catch(() => {
      console.error('Failed to retrieve dumpster sizes')
    })
  },
  data () {
    return {
      availableDumpsters: {},
      selectedDumpsterId: '',
      areDumpsterOptionsLoaded: false
    }
  },
  methods: {
    updateRelatedDumpsterSize () {
      const sizeListMap = { 'TEMPORARY': 'temporaryDumpsterSizes', 'PERMANENT': 'permanentDumpsterSizes', 'SPECIAL_USE': 'specialUseDumpsterSizes' }
      const [selectedCategory, selectedId] = this.selectedDumpster.split(':')
      this.relationships = { [sizeListMap[selectedCategory]]: [selectedId] }
    }
  },
  computed: {
    heading: {
      get () {
        return {
          text: this.page.modules[this.moduleIndex].data.headingText,
          tag: this.page.modules[this.moduleIndex].data.headingTag
        }
      },
      set (value) {
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingText',
          value: value.text
        })
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingTag',
          value: value.tag
        })
      }
    },
    ...mapModuleAttributes(['selectedDumpster', 'copyText', 'copySide', 'product', 'relationships'])
  }
}
</script>

<style scoped lang="scss">
  @import '~@budgetdumpster/scms-ui/src/assets/scss/base/variables';
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';

  .section {
    &-head {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: map-get($spacers, 2);

      &-title {
        font-size: $h4-font-size;
      }
    }

    &-body {
      background-color: $light-gray;
      padding: map-get($spacers, 4) map-get($spacers, 3);
      margin-bottom: map-get($spacers, 4);
    }
  }

  .img-thumbnail {
    max-width: 125px;
    max-height: 125px;
  }
</style>

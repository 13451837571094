<template>
  <scms-module-accordion-card :title="moduleTitle" :id-name="$options.name" v-model="moduleId">
    <template slot="component">
      <scms-select class="mb-3" v-model="formType" label="Select a Form"
          :options="moduleMeta.formTypes" :should-return-key="true"/>
      <scms-image-inputs v-if="isContained" label="Desktop Background Image"
        @source="desktopImageSrc = $event" :image="{ source: desktopImageSrc }" :hide-alt="true"/>
      <scms-image-inputs v-if="isContained" label="Tablet Background Image"
        @source="tabletImageSrc = $event" :image="{ source: tabletImageSrc }" :hide-alt="true"/>
      <scms-image-inputs v-if="isContained" label="Mobile Background Image"
        @source="mobileImageSrc = $event" :image="{ source: mobileImageSrc }" :hide-alt="true"/>
      <p class="py-3 font-italic text-muted d-block mx-auto" v-if="!isContained">Note: Background images cannot be used with Full Width. Update Module Width to Contained to use a background image.</p>
    </template>
    <template slot="component-styles">
      <div class="d-flex flex-wrap">
        <fieldset class="form-group mr-4" v-if="!isBackgroundSet">
          <legend class="col-form-label pt-0">Module Width</legend>
          <label class="form-check-inline">
            <input class="form-check-input" type="radio" :name="'width' + moduleIndex" :value="true" v-model="isContained">
            Contained
          </label>
          <label class="form-check-inline">
            <input class="form-check-input" type="radio" :name="'width' + moduleIndex" :value="false" v-model="isContained">
            Full Width
          </label>
        </fieldset>
        <p class="py-2 font-italic text-muted w-100" v-if="isBackgroundSet">Note: Full Width cannot be used with a background image. Remove all background images to set this module to Full Width.</p>
        <fieldset class="form-group mr-4">
          <legend class="col-form-label pt-0">Breadcrumbs</legend>
          <label class="form-check-inline">
            <input class="form-check-input" type="radio" :name="'breadcrumbs' + moduleIndex" :value="true" v-model="showBreadcrumbs">
            Show
          </label>
          <label class="form-check-inline">
            <input class="form-check-input" type="radio" :name="'breadcrumbs' + moduleIndex" :value="false" v-model="showBreadcrumbs">
            Hide
          </label>
        </fieldset>
      </div>
      <div class="d-flex flex-wrap">
        <scms-select class="mr-4" v-if="moduleTopPaddingOptions" v-model="paddingTop"
          :options="moduleTopPaddingOptions" label="Top Padding" :should-return-key="true"/>
        <scms-select class="mr-4" v-if="moduleBottomPaddingOptions" v-model="paddingBottom"
          :options="moduleBottomPaddingOptions" label="Bottom Padding" :should-return-key="true"/>
        <scms-select class="mr-4" v-if="moduleMeta.backgroundColors" v-model="backgroundColor"
          :options="moduleMeta.backgroundColors" label="Background Color" :should-return-key="true"/>
      </div>
    </template>
    <template slot="component-footer">
      <label class="col-6">Module Unique ID Name
        <input type="text" class="form-control" v-model="moduleId">
      </label>
    </template>
  </scms-module-accordion-card>
</template>

<script>
import { mapGetters } from 'vuex'
// import { moduleMixin } from '../../mixins/ModuleMixin'
// import { mapModuleAttributes } from '../../utilities/MapModuleAttributes'
// import { deepCopy } from '../../utilities/DeepCopy'
// import ScmsModuleAccordionCard from '../../components/ModuleAccordionCard'
// import ScmsImageInputs from '../ImageInputs'
// import ScmsSelect from '../Select'

import { moduleMixin, mapModuleAttributes, deepCopy, ScmsModuleAccordionCard, ScmsSelect, ScmsImageInputs } from '@budgetdumpster/scms-ui'

export default {
  name: 'scms-full-width-form',
  components: {
    ScmsImageInputs,
    ScmsModuleAccordionCard,
    ScmsSelect
  },
  mixins: [moduleMixin],
  computed: {
    ...mapModuleAttributes([
      'desktopImageSrc',
      'formType',
      'isContained',
      'mobileImageSrc',
      'tabletImageSrc',
      'showBreadcrumbs'
    ]),
    ...mapGetters('moduleStore', ['findModuleByComponentName']),
    isBackgroundSet: function () {
      return Boolean(this.desktopImageSrc) || Boolean(this.tabletImageSrc) || Boolean(this.mobileImageSrc)
    }
  },
  methods: {
    itemDefault (property) {
      const appModule = this.findModuleByComponentName(this.$options.name)
      return deepCopy(appModule.defaults[property][0])
    }
  }
}
</script>

export const currentDumpsterSizeStore = {
  namespaced: true,
  state: {
    dumpsterSize: {}
  },
  getters: {},
  actions: {},
  mutations: {
    setDumpsterSize (state, payload) {
      state.dumpsterSize = payload.dumpsterSize
    },
    setAttribute (state, payload = { name: null, value: null }) {
      if (!payload.name) return

      const dumpsterSizeClone = { ...state.dumpsterSize }
      dumpsterSizeClone[payload.name] = payload.value
      state.dumpsterSize = { ...dumpsterSizeClone }
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('scms-search-box',{attrs:{"resource-to-search":"markets","search-params":_vm.searchParams},on:{"searchData":_vm.handleSearch,"clearSearch":_vm.clearSearch}},[_c('scms-input-with-counter',{staticClass:"col-2",attrs:{"label":"Name"},model:{value:(_vm.searchParams.name),callback:function ($$v) {_vm.$set(_vm.searchParams, "name", $$v)},expression:"searchParams.name"}}),_c('scms-input-with-counter',{staticClass:"col-2",attrs:{"label":"Phone Number"},model:{value:(_vm.searchParams.phoneNumber),callback:function ($$v) {_vm.$set(_vm.searchParams, "phoneNumber", $$v)},expression:"searchParams.phoneNumber"}})],1),_c('scms-resource-list',{attrs:{"has-next-page":_vm.hasNextPage,"has-previous-page":_vm.hasPreviousPage,"next-cursor":_vm.nextCursor,"previous-cursor":_vm.previousCursor,"nodes":_vm.markets,"display-name":"Market","plural-display-name":"Markets","add-new-url":"/markets/create","columns":{
      Name: "name",
      "Phone Number": "phoneNumber",
      "Call for Pricing": function (node) { return node.isCallForPricing ? "Yes" : "No"; }
    }},on:{"paginate":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"leftSideRowActions",fn:function(ref){
    var node = ref.node;
return [_c('router-link',{staticClass:"btn btn-sm edit-button mx-2",attrs:{"to":{name: "market.edit", params: { id: node.id }},"tag":"button"}},[_vm._v("Edit")])]}},{key:"rightSideRowActions",fn:function(ref){
    var node = ref.node;
return [_c('button',{staticClass:"btn btn-sm btn-success mx-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.cloneMarket(node)}}},[_vm._v("Clone")]),_c('button',{staticClass:"btn btn-sm btn-danger mx-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.promptForDelete(node)}}},[_vm._v(" Delete ")])]}}])}),_c('scms-modal',{attrs:{"name":"delete-market-modal","title":"Confirm Delete"}},[_c('template',{slot:"body"},[_vm._v(" Are you sure you want to delete the market? ")]),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.deleteMarket}},[_vm._v("Delete")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
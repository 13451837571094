<template>
  <scms-module-accordion-card :title="moduleTitle">
    <template slot="component">
      <label class="d-flex flex-wrap form-group">
        Custom HTML
        <textarea class="form-control" v-model="moduleHTML"/>
      </label>
      <label class="d-flex flex-wrap form-group">
        Custom Script
        <textarea class="form-control" v-model="moduleScript"/>
      </label>
    </template>
    <template slot="component-styles">
      <div class="row">
        <scms-select class="col-4" v-if="moduleTopPaddingOptions" v-model="paddingTop"
          :options="moduleTopPaddingOptions" label="Top Padding" :should-return-key="true"/>
        <scms-select class="col-4" v-if="moduleBottomPaddingOptions" v-model="paddingBottom"
          :options="moduleBottomPaddingOptions" label="Bottom Padding" :should-return-key="true"/>
      </div>
      <div class="row">
        <scms-select class="col-4" v-if="moduleMeta.backgroundColors" v-model="backgroundColor"
          :options="moduleMeta.backgroundColors" label="Background Color" :should-return-key="true"/>
      </div>
      <label>
        <input type="checkbox" v-model="contained">
        Display module inside container
      </label>
    </template>
    <template slot="component-footer">
      <label class="col-6">
        Module Unique ID Name
        <input type="text" class="form-control" v-model="moduleId">
      </label>
    </template>
  </scms-module-accordion-card>
</template>

<script>
import { mapModuleAttributes, moduleMixin, ScmsModuleAccordionCard, ScmsSelect } from '@budgetdumpster/scms-ui'

export default {
  name: 'scms-custom-html',
  mixins: [moduleMixin],
  components: {
    ScmsModuleAccordionCard,
    ScmsSelect
  },
  computed: {
    ...mapModuleAttributes(['backgroundColor', 'contained', 'moduleHTML', 'moduleScript'])
  }
}
</script>

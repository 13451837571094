import { configureErrorMessages, createErrorMessage } from '@budgetdumpster/scms-ui'

/**
 * @type {Object}
 */
const ERROR_MESSAGES = {
  cloneCity: createErrorMessage('clone the existing City.'),
  createCity: createErrorMessage('create the new City.'),
  createDumpsterSize: createErrorMessage('create the new Dumpster.'),
  createMarket: createErrorMessage('create the new Market.'),
  deleteCity: createErrorMessage('delete the existing City.'),
  deleteDumpsterSize: createErrorMessage('delete the existing Dumpster.'),
  deleteMarket: createErrorMessage('delete the existing Market'),
  updateCity: createErrorMessage('update the existing City'),
  updateDumpsterSize: createErrorMessage('update the existing Dumpster'),
  updateMarket: createErrorMessage('update the existing Market.')
}

/**
 * @description Add new messages into the error store messages collection
 */
export const configureErrors = () => {
  configureErrorMessages(ERROR_MESSAGES)
}

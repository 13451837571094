<template>
  <scms-app/>
</template>

<script>
import ScmsApp from '@budgetdumpster/scms-ui/src/App'
export default {
  name: 'scms-dumpsters-com-app',
  components: {
    ScmsApp
  },
  mounted () {
    const googleMapScript = document.createElement('script')
    googleMapScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&libraries=places`)

    document.head.appendChild(googleMapScript)
  }
}
</script>

<template>
  <scms-module-accordion-card :title="(heading.tag) ? `${moduleTitle} [${heading.tag}]` : moduleTitle">
    <template slot="component">
      <scms-heading-with-level v-model="heading"/>
      <scms-copy-block v-model="topCopyText" label="Top Copy Block"/>
      <scms-item-list v-model="products" item-name="scms-related-product-list-item" :new-item-data="productItemDefault()"
        list-name="Product Section" :meta="moduleMeta" @input="updateRelationship"/>
      <scms-copy-block v-model="bottomCopyText" label="Bottom Copy Block"/>
    </template>
    <template slot="component-styles">
      <div class="row">
        <scms-select class="col-4" v-if="moduleTopPaddingOptions" v-model="paddingTop"
          :options="moduleTopPaddingOptions" label="Top Padding" :should-return-key="true"/>
        <scms-select class="col-4" v-if="moduleBottomPaddingOptions" v-model="paddingBottom"
          :options="moduleBottomPaddingOptions" label="Bottom Padding" :should-return-key="true"/>
      </div>
    </template>
    <template slot="component-footer">
      <label class="col-6">
        Module Unique ID Name
        <input type="text" class="form-control" v-model="moduleId">
      </label>
    </template>
  </scms-module-accordion-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { moduleMixin, mapModuleAttributes, deepCopy, ScmsModuleAccordionCard, ScmsHeadingWithLevel, ScmsCopyBlock, ScmsItemList, ScmsSelect } from '@budgetdumpster/scms-ui'

export default {
  name: 'scms-product-cards-main',
  components: {
    ScmsItemList,
    ScmsModuleAccordionCard,
    ScmsHeadingWithLevel,
    ScmsCopyBlock,
    ScmsSelect
  },
  mixins: [moduleMixin],
  computed: {
    ...mapGetters('moduleStore', ['findModuleByComponentName']),
    heading: {
      get () {
        return {
          text: this.page.modules[this.moduleIndex].data.headingText,
          tag: this.page.modules[this.moduleIndex].data.headingTag
        }
      },
      set (value) {
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingText',
          value: value.text
        })
        this.setModuleAttribute({
          index: this.moduleIndex,
          name: 'headingTag',
          value: value.tag
        })
      }
    },
    ...mapModuleAttributes(['topCopyText', 'products', 'relationships', 'bottomCopyText'])
  },
  methods: {
    updateRelationship () {
      this.relationships = {
        temporaryDumpsterSizes: [],
        permanentDumpsterSizes: [],
        specialUseDumpsterSizes: []
      }
      for (let product of this.products) {
        if (!Object.keys(product.relationship).length) return
        let productRelationshipCategory = Object.keys(product.relationship)[0]
        this.relationships[productRelationshipCategory].push(product.relationship[productRelationshipCategory][0])
      }
    },
    productItemDefault () {
      const appModule = this.findModuleByComponentName(this.$options.name)
      return deepCopy(appModule.defaults.products[0])
    }
  }
}
</script>
